import React, { memo, useState, useContext, useEffect } from 'react';
import {
  Grid,
  Paper,
  TextField,
  MenuItem,
} from "@material-ui/core";

import useWindowSize from "../helper/UseWindowsSize";
import UserList from "./UserList";
import CustomerList from "./CustomerList"
import UserInformation from "./UserInformation";
import {
  getUsers,
  getUserDetail,
  putUser,
} from "./Api";

import {
  isOK,
} from "../helper/RestStatus";

import { SnackbarContext } from "../components/snackbar/SnackbarContext";
import uuid from "react-uuid";
import Loader from "../components/loader/Loader";
import useTextField from "../helper/UseTextField";
import TaFab from "../components/fab/TaFab";
import { useForm } from "react-hook-form";
import { RHFInput } from "react-hook-form-input";
import TaButton from "../components/button/TaButton";
import UserBottomBar from './UserBottomBar';

const statusUsers = [
  {
    id: "-1",
    name: 'Pendiente por aprobación'
  },
  {
    id: "0",
    name: 'Inactivos'
  },
  {
    id: "1",
    name: 'Activos'
  },
  {
    id: "2",
    name: 'Rechazados'
  }
]

const initialCustomer = {
  email: "",
  uniqueKey: "",
  idClient: "",
  name: "",
  phone: "",
  client: [],
  active: -100,
  typeUser: '',
}

const User = memo(({ userId }) => {
  const size = useWindowSize();
  const [height] = useState(size.height);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(initialCustomer);
  const [loader, setLoader] = useState(false);
  const { sbDispatch } = useContext(SnackbarContext);
  const { handleSubmit, register, errors, setValue, watch } = useForm();
  const txtCommentary = useTextField('Commentary', 'Motivo de rechazo');
  const currentStatus = watch('statusUser')

  const fnOnSave = async (data = {}, event) => {
    try {
      let statusActive;
      if(!(event === undefined)){
        switch (event.nativeEvent.submitter.id) {
          case 'btnRejectUser':
            statusActive = 2
            break;
          case 'btnApproveUser':
            statusActive = 1
            break;
          default:
            statusActive = selectedUser.active
            break;
        }
      } else {
        statusActive = selectedUser.active
      }
      setLoader(true);
      const response = await putUser(userId, statusActive, selectedUser, data.Commentary);
      setLoader(false);
      if (response.data.errorCode === 0) setSelectedUser(initialCustomer)
      return sbDispatch.api(response.status, response.data);
    } catch (error) {
      setLoader(false);
      console.error(`fnOnSave => ${error}`)
    }
  }

  const fnOnClickUser = async (data) => {
    setLoader(true);
    // MODIFICADO POR Reinaldo Zambrano
    // AGREGADA FUNCION DE RESETEO DE CLIENTE INICIAL PARA LIMPIAR EL FORMULARIO CUANDO SE SELECCIONE UN USUARIO DESDE LA LISTA
    setSelectedUser(initialCustomer)
    const response = await getUserDetail(data.idUser);
    setLoader(false);
    if (isOK(response.status)) {
      if (response.data.errorCode === 0) {
        return setSelectedUser({ ...response.data.data, client: response.data.data.client.map(x => ({ id: x.idClient, name: x.name })) });
      }
    }
    return sbDispatch.api(response.status, response.data);
  }

  const fnOnClickCustomer = (data) => {
    if (!(selectedUser.client.find(x => x.id === data.id))) {
      setSelectedUser(val => ({ ...val, client: val.client.concat(data) }))
    }
  }

  const fnOnSubmitStatusUser = async (data) => {
    setLoader(true);
    setSelectedUser(initialCustomer)
    const response = await getUsers(data.statusUser);
    setLoader(false);
    if (isOK(response.status)) {
      if (response.data.errorCode === 0) {
        return setUsers(response.data.data.map(x => ({ ...x, uniqueKey: uuid() })));
      }
    }
    return sbDispatch.api(response.status, response.data);
  }

  const fnOnClickClient = (data) => setSelectedUser(val => ({ ...val, client: val.client.filter(x => x.id !== data.id) }))

  const statusUser = useTextField('statusUser', 'Estatus de usuario', errors, "text");
  return (
    <Paper style={{ padding: "5px", height: height - 122 }} id={'paperPpal'}>
      <Grid container alignContent="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <form onSubmit={handleSubmit(fnOnSubmitStatusUser)}>
            <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
              <Grid item xs={12} sm={9} md={10} lg={10} xl={11}>
                <RHFInput
                  as={
                    <TextField
                      {...statusUser}
                      select
                      fullWidth
                      disabled={loader}>
                      {statusUsers.map(option => <MenuItem value={option.id} key={option.id}>{`${option.name}`}</MenuItem>)}
                    </TextField>
                  }
                  name={statusUser.name}
                  register={register({ required: "Requerido" })}
                  setValue={setValue} />
              </Grid>
              <Grid item xs={12} sm={3} md={2} lg={2} xl={1} align="right">
                <TaFab
                  icon="search"
                  type="submit"
                  disabled={loader} />
              </Grid>
            </Grid>
          </form>
          <Paper style={{ padding: "5px", height: height - 253 }}>
            <UserList data={users} onClick={fnOnClickUser} height={height - 145} disabled={loader} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Paper style={{ padding: "5px", height: height - 197, overflowX: 'hidden', overflowY: 'hidden !important' }}>
            <UserInformation selectedUser={selectedUser} onClick={fnOnClickClient} setSelectedUser={setSelectedUser} disabled={selectedUser.active === -100} height={height - 624}/>
          </Paper>
        </Grid>
        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Paper style={{ padding: "5px", height: height - 197 }}>
            <CustomerList onClick={fnOnClickCustomer} height={height - 80} loader={loader} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right" hidden={selectedUser.active === -100 || selectedUser.active !== -1}>
          <UserBottomBar txtCommentary={txtCommentary} onSave={fnOnSave} disabled={loader} hidden={currentStatus !== '-1'}/>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right" hidden={selectedUser.active !== 1 && selectedUser.active !== 0}>
          <TaButton
            id="btnSaveUser"
            label={'Guardar'}
            onClick={fnOnSave}
            disabled={loader} />
        </Grid>
        <Loader isLoading={loader} />
      </Grid>
    </Paper>
  );
});

export default User;