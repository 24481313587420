import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
} from "@material-ui/core";

import TaTypography from "../components/typography/TaTypography";
import TaFab from '../components/fab/TaFab';

const CxcResume = memo(({ totalBalance, totalPaidOut, totalDeferred, onClickOpenModal, disabled }) => {
    return (
        <Grid container spacing={1} alignItems={'center'} justify={'center'}>
            <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                <TaFab
                    id={'btnAddAdvPayment'}
                    icon={'card'}
                    color={'blue'}
                    onClick={onClickOpenModal}
                    tooltip={'Anticipos'}
                    disabled={disabled} />
            </Grid>
            <Grid item xs={10} sm={10} md={10} lg={10} xl={10} align={'right'}>
                <Grid container>
                    <Grid item xs={12} sm={6} md={7} lg={8} xl={9}>
                        <TaTypography fontSize="14pt" align='right'>{`Saldo total:`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
                        <TaTypography fontSize="14pt" align='right'>{`${totalBalance.formatNumb(2)}`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={7} lg={8} xl={9}>
                        <TaTypography fontSize="14pt" align='right'>{`Diferido:`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
                        <TaTypography fontSize="14pt" align='right'>{`${totalDeferred.formatNumb(2)}`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={7} lg={8} xl={9}>
                        <TaTypography fontSize="14pt" align='right'>{`Total a pagar:`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={6} md={5} lg={4} xl={3}>
                        <TaTypography fontSize="14pt" align='right'>{`${totalPaidOut.formatNumb(2)}`}</TaTypography>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
});

CxcResume.propTypes = {
    totalBalance: PropTypes.number,
    totalPaidOut: PropTypes.number,
};

export default CxcResume;