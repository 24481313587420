import React, { memo, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Grid,
  Paper,
  MenuItem,
  TextField,
  Link,
} from "@material-ui/core/"

import { RHFInput } from "react-hook-form-input";

import TaTypography from "../components/typography/TaTypography";
import { useForm } from "react-hook-form";
import useTextField from '../helper/UseTextField';
import NumberTextField from "../components/textfield/NumberTextField";

import {
  regexDate,
} from "../helper/RegularExpressions"

import TaFab from "../components/fab/TaFab";
import moment from 'moment'

const fnValidateAmount = (payment, value, exchangeFactor, maxAmount, isAdvance) => {
  const newValue = payment.coin === '$' ? value : +(value / exchangeFactor).toFixed(2)
  // MODIFICADO POR Reinaldo Zambrano
  // AGREGUE UN PARAMETRO isAdvance QUE ES UN BOOLEANO, SI ES UN ADELANTO ENTONCES RETORNA EL VALOR SIN VERIFICAR LA DEUDA O EL TOTAL PAGADO
  return isAdvance ? +newValue : +newValue <= +maxAmount || "El monto supera el saldo total"
}

const actualDate = moment(new Date()).format("DD/MM/YYYY")

const PaymentMethods = memo(({ data, onSubmit, height, totalPaidOut, totalDebt, isAdvance, isLoading, exchangeFactor }) => {
  const [currentPayMethod, setCurrentPayMethod] = useState();
  const { handleSubmit, register, errors, setValue, watch, getValues, reset } = useForm();
  const payMethod = useTextField('payMethod', 'Seleccionar', errors, 'text');
  const tranDate = useTextField('tranDate', 'Fecha del pago', errors, 'date');
  const tranNumber = useTextField('tranNumber', 'Nro Transacción', errors, 'text');
  const watchPayMethod = watch('payMethod');
  const watchDate = watch('tranDate')
  const [sameDate, setSameDate] = useState(false)

  const fnOnSubmit = (data) => {
    onSubmit(currentPayMethod, data)
    // setValue('exchangeFactor', '');
    setValue('amount', '');
    reset();
  }

  useEffect(() => {
    if (watchPayMethod) {
      setCurrentPayMethod(data.find(x => x.id === watchPayMethod));
    }
  }, [watchPayMethod])

  useEffect(() => {
    try {
      if (currentPayMethod && currentPayMethod.coin === 'BS') {
        if (actualDate === moment(watchDate).format("DD/MM/YYYY")) {
          setSameDate(true)
          return setValue('exchangeFactor', exchangeFactor)
        }
        setSameDate(false)
        return setValue('exchangeFactor', '')
      }
      return
    } catch (error) {
      console.error(`Validacion de fechas => ${error}`)
      setSameDate(false)
      return setValue('exchangeFactor', '')
    }
  }, [currentPayMethod, watchDate])

  const disabled = totalPaidOut === 0 && isAdvance === false
  return (
    <Grid container alignContent="flex-start" alignItems="center" spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TaTypography fontSize='14pt' align='center' color="var(--main-text-color2)" text={`Métodos de pago`} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper elevation={0} style={{ height: `${height - 50}px` }}>
          <form onSubmit={handleSubmit(fnOnSubmit)}>
            <Grid container alignContent="flex-start" alignItems="flex-start" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <RHFInput
                  as={
                    <TextField
                      {...payMethod}
                      select
                      fullWidth
                      disabled={disabled}>
                      {
                        data.filter(x => x.active === true && (isAdvance) ? x.coin === '$' : x.coin === x.coin)
                          .map(option => <MenuItem key={option.uniqueKey} value={option.id}>{`${option.name}`}</MenuItem>)
                      }
                    </TextField>
                  }
                  name={'payMethod'}
                  register={register({ required: "Requerido" })}
                  setValue={setValue} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  {...tranDate}
                  fullWidth
                  inputRef={register({ required: "Requerido", pattern: regexDate, })}
                  disabled={disabled} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TextField
                  {...tranNumber}
                  fullWidth
                  inputRef={register({ required: "Requerido" })}
                  disabled={disabled} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} hidden={isAdvance || (currentPayMethod && currentPayMethod.coin === '$')}>
                <NumberTextField
                  name="exchangeFactor"
                  label="Factor de cambio"
                  register={register({
                    required: {
                      value: (currentPayMethod) ? currentPayMethod.coin === 'BS' : false,
                      message: "Requerido"
                    }
                  })}
                  errors={errors}
                  setValue={setValue}
                  disabled={disabled || sameDate} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <NumberTextField
                  name="amount"
                  label="Monto"
                  register={register({
                    required: "Requerido",
                    validate: value => fnValidateAmount(currentPayMethod, value, getValues('exchangeFactor'), totalPaidOut - totalDebt, isAdvance)
                  })}
                  errors={errors}
                  setValue={setValue}
                  disabled={disabled} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'center'}>
                {/* Agregado disabled al boton de agregar metodos de pagos si no se ha seleccionado un documento */}
                <TaFab
                  icon={isAdvance ? 'save' : "add"}
                  color="green"
                  type="submit"
                  disabled={disabled || isLoading} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TaTypography align="center"><Link target="_blank" href="https://academiawashington.totalaplicaciones.com/documents/cuentas.pdf" color="inherit">Cuentas</Link></TaTypography>
              </Grid>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
});

PaymentMethods.propTypes = {
  data: PropTypes.array.isRequired,
  disabled: PropTypes.bool,
  isAdvance: PropTypes.bool,
};

PaymentMethods.defaultProps = {
  disabled: true,
  isAdvance: false,
}

export default PaymentMethods;