
export const fnSearchOnData = (data, column, filter) => {
    if (filter !== '') {
        const newFilter = String(filter.toLowerCase()).replace(/\*/g, ".*");
        const newMatch = new RegExp('^.*' + newFilter + '.*$');
        return data.filter(x => (newMatch.test(String(x[column]).toLowerCase()))).slice(0, 100);
    } else {
        return data;
    }
}


export const fnSortData = (data) => data.sort(compareColumns);

const compareColumns = (a, b) => {
    const nameA = a.name;
    const nameB = b.name;
    let comparison = 0;
    if (nameA > nameB) {
        comparison = 1;
    } else if (nameA <= nameB) {
        comparison = -1;
    }
    return comparison;
}