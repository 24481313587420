export const regexTotalAppId = {
    value: /^[A-Z0-9]{4,20}$/i,
    message: "Alias invalido, debe ser alfanumerico de 4 a 20 caracteres"
}

export const regexTotalAppIdLogin = {
    value: /^[A-Z0-9@]{4,40}$/i,
    message: "Alias invalido"
}

export const regexName = {
    value: /^[A-zÀ-ú ]{3,40}$/i,
    message: "Debe ser alfanumerico de 3 caracteres en adelante"
}

export const regexCompanyId = {
    value: /^[A-Z0-9-]{3,25}$/i,
    message: "Id invalido"
}

export const regexCompanyName = {
    value: /^[A-zÀ-ú0-9 -.,]{3,60}$/i,
    message: "Nombre invalido"
}

export const regexEmail = {
    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
    message: "Correo invalido"
}

export const regexPass = {
    value: /^(?=.*\d)(?=.*[A-Z])[0-9a-zA-Z]{6,}$/,
    message: "Contraseña invalida, debe contener al menos un numero, una letra en mayuscula y 6 caracteres"
}

export const regexSimplePass = {
    value: /^[0-9a-zA-Z]{6,}$/,
    message: "Contraseña invalida"
}

export const regexDate = {
    value: /([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/,
    message: "Fecha invalida"
}

export const regexAddress = {
    value: /^[#.0-9A-zÀ-ú \s,-]{2,40}$/,
    message: "Dirección invalida",
}

export const regexPhone = {
    value: /^(\+\d{1,3}[- ]?)?[0]?\d{10}$/,
    message: "Teléfono invalido",
}

