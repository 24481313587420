import {
  CXC_INITIALSTATE,
  CXC_SETPAYMENTMETHODS,
  CXC_SET,
  CXC_ONCHANGETABLE,
  CXC_SELECTEDPAYMENTMETHOD,
  CXC_ONCHANGEPAYMENT,
  CXC_RESETUI,
  CXC_SETFACTOR,
  CXC_CHANGECURRENCY,
  CXC_DELSELECTEDPAYMENTMETHOD,
  CXC_ONSUBMITPAYMENT,
  CXC_MODALSETVALUE,
  CXC_SETADVANCELIST,
} from "./CxcConstants";

import uuid from "react-uuid";

import {
  sumValues,
} from "../helper/ArrayObjects";

import { fnChangeCurrency, fnChangeCurrencyRev } from "../helper/ChangeCurrency";

import moment from "moment";

let newState, newTotalDebt = 0;

//MODIFICADO POR Reinaldo Zambrano
// Agregados bloques try catch para control de errores y prevencion de pantallas en blanco.
const CxcReducer = (state, action) => {
  switch (action.type) {

    case CXC_SETPAYMENTMETHODS:
      try {
        return {
          ...state,
          paymentMethods: action.data.filter(x => x.active === true).map(x => ({ id: x.id, coin: x.coin, active: x.active, uniqueKey: uuid(), name: `(${x.coin}) ${x.bankName}` }))
        }
      } catch (error) {
        console.error(CXC_SETPAYMENTMETHODS, error)
        return state
      }

    case CXC_SET:
      try {
        newState = {
          ...state,
          cxc: action.data.map(x => ({
            ...x,
            uniqueKey: uuid(),
            active: false,
            paidOut: fnChangeCurrency((x.balanceCoinEx - x.amountDeferred), state.exchangeFactor, state.defaultCurrency),
            dateE: moment(new Date(x.dateE)).format("DD-MM-YYYY"),
            amount: fnChangeCurrency(x.amountCoinEx, state.exchangeFactor, 0),
            balance: fnChangeCurrency(x.balanceCoinEx, state.exchangeFactor, 0),
            amountLabel: fnChangeCurrency(x.amountCoinEx, state.exchangeFactor, state.defaultCurrency),
            balanceLabel: fnChangeCurrency(x.balanceCoinEx, state.exchangeFactor, state.defaultCurrency),
            referredLabel: fnChangeCurrency(x.amountDeferred, state.exchangeFactor, state.defaultCurrency),
            currentCurrency: state.defaultCurrency,
          })),
          totalBalance: sumValues(action.data, 'balanceCoinEx'),
          totalDeferred: sumValues(action.data, 'amountDeferred'),
          totalPaidOut: 0,
          totalDebt: 0,
        }

        return {
          ...state,
          ...newState,
          totalBalanceLabel: fnChangeCurrency(newState.totalBalance, state.exchangeFactor, state.defaultCurrency),
          totalPaidOutLabel: fnChangeCurrency(newState.totalPaidOut, state.exchangeFactor, state.defaultCurrency),
          totalDeferredLabel: fnChangeCurrency(newState.totalDeferred, state.exchangeFactor, state.defaultCurrency),
        }
      } catch (error) {
        console.error(CXC_SET, error)
        return state
      }

    case CXC_ONCHANGETABLE:
      try {
        newState = {
          ...state,
          cxc: state.cxc.map(x => x.uniqueKey !== action.uniqueKey
            ? (x.active) ? ({ ...x, active: false }) : x
            : ({ ...x, [action.name]: action.value }))
        }

        const newTotalPaidOut = sumValues(newState.cxc.filter(x => (x.active)), 'paidOut');

        newTotalDebt = 0;
        newState.selectedPaymentMethods.forEach(x => {
          newTotalDebt += x.currentCurrency === 1 ? x.amount : x.amount / state.exchangeFactor
        });

        return {
          ...state,
          ...newState,
          totalPaidOut: newTotalPaidOut,
          totalDebt: +(newTotalDebt.toFixed(2)),
          totalPaidOutLabel: newTotalPaidOut,
        }
      } catch (error) {
        console.error(CXC_ONCHANGETABLE, error)
        return state
      }
    case CXC_SELECTEDPAYMENTMETHOD:
      try {
        const currentTotalPaidOut = state.defaultCurrency === 1 ? state.totalPaidOut : state.totalPaidOut / state.exchangeFactor;
        if (state.totalDebt >= currentTotalPaidOut) {
          return state;
        }
        const newPaymentAmount = fnChangeCurrency(currentTotalPaidOut - state.totalDebt, state.exchangeFactor, +(action.data.coin === "$"))
        newState = {
          ...state,
          selectedPaymentMethods: state.selectedPaymentMethods.concat({
            ...action.data,
            amount: newPaymentAmount,
            date: '',
            uniqueKey: uuid(),
            refere: '',
            maxValue: newPaymentAmount,
            currentCurrency: +(action.data.coin === "$"),
            factor: state.exchangeFactor,
          }),
        }

        newTotalDebt = 0;
        newState.selectedPaymentMethods.forEach(x => {
          newTotalDebt += x.currentCurrency === 1 ? x.amount : x.amount / state.exchangeFactor
        });

        return {
          ...state,
          ...newState,
          totalDebt: +(newTotalDebt.toFixed(2)),
        }
      } catch (error) {
        console.error(CXC_SELECTEDPAYMENTMETHOD, error)
        return state
      }

    case CXC_ONCHANGEPAYMENT:
      try {
        let currentPayed = state.defaultCurrency === 1 ? state.totalPaidOut : state.totalPaidOut / state.exchangeFactor,
          currentAmount = 0;

        newState = {
          ...state,
          selectedPaymentMethods: state.selectedPaymentMethods.map(x => {
            if (action.name !== 'amount') {
              return (x.uniqueKey !== action.uniqueKey) ? x : ({ ...x, [action.name]: action.value })
            } else {
              let currentValue = x.uniqueKey !== action.uniqueKey ? x.amount : action.value;
              currentValue = x.currentCurrency === 1 ? currentValue : currentValue / state.exchangeFactor

              if (currentValue > currentPayed) {
                currentAmount = currentPayed
              } else {
                currentAmount = currentValue
                currentPayed -= currentValue
              }
              currentAmount = x.currentCurrency === 1 ? currentValue : currentValue * state.exchangeFactor
              return ({ ...x, amount: x.uniqueKey !== action.uniqueKey ? currentAmount : action.value })
            }
          })
        }

        newTotalDebt = 0;
        newState.selectedPaymentMethods.forEach(x => {
          newTotalDebt += x.currentCurrency === 1 ? x.amount : x.amount / state.exchangeFactor
        });

        return {
          ...state,
          ...newState,
          totalDebt: +(newTotalDebt.toFixed(2)),
        }
      } catch (error) {
        console.error(CXC_ONCHANGEPAYMENT, error)
        return state
      }

    case CXC_RESETUI:
      try {
        return {
          ...state,
          totalDebt: 0,
          totalPaidOut: 0,
          totalBalance: 0,
          cxc: [],
          selectedPaymentMethods: [],
        }
      } catch (error) {
        console.error(CXC_RESETUI, error)
        return state
      }

    case CXC_SETFACTOR:
      try {
        return {
          ...state,
          exchangeFactor: action.data.factor
        };
      } catch (error) {
        console.error(CXC_SETFACTOR, error)
        return state
      }

    case CXC_CHANGECURRENCY:
      try {
        const newDefaultCurrency = state.defaultCurrency === 1 ? 0 : 1
        return {
          ...state,
          defaultCurrency: newDefaultCurrency,
          cxc: state.cxc.map(x => ({
            ...x,
            amountLabel: fnChangeCurrency(x.amountCoinEx, state.exchangeFactor, newDefaultCurrency),
            balanceLabel: fnChangeCurrency(x.balanceCoinEx, state.exchangeFactor, newDefaultCurrency),
            referredLabel: fnChangeCurrency(x.amountDeferred, state.exchangeFactor, newDefaultCurrency),
            currentCurrency: newDefaultCurrency,
            paidOut: fnChangeCurrencyRev(x.paidOut, state.exchangeFactor, x.currentCurrency),
          })),
          totalBalanceLabel: fnChangeCurrency(state.totalBalance, state.exchangeFactor, newDefaultCurrency),
          totalPaidOutLabel: fnChangeCurrencyRev(state.totalPaidOut, state.exchangeFactor, state.defaultCurrency),
          exchangeLabel: newDefaultCurrency === 1 ? "$" : "Bs " + state.exchangeFactor.formatNumb(2),
        }
      } catch (error) {
        console.error(CXC_CHANGECURRENCY, error)
        return state
      }

    case CXC_DELSELECTEDPAYMENTMETHOD:
      try {
        newState = {
          ...state,
          selectedPaymentMethods: state.selectedPaymentMethods.filter(x => x.uniqueKey !== action.uniqueKey),
        }

        newTotalDebt = 0;
        newState.selectedPaymentMethods.forEach(x => {
          newTotalDebt += x.currentCurrency === 1 ? x.amount : (x.amount / state.exchangeFactor)
        });
        return {
          ...state,
          ...newState,
          totalDebt: +(newTotalDebt.toFixed(2)),
        }
      } catch (error) {
        console.error(CXC_DELSELECTEDPAYMENTMETHOD, error)
        return state
      }

    case CXC_ONSUBMITPAYMENT:
      try {
        let actualFactor = state.exchangeFactor
        newState = {
          ...state,
          selectedPaymentMethods: state.selectedPaymentMethods.concat({
            ...action.payment,
            amount: action.data.amount,
            date: action.data.tranDate,
            uniqueKey: uuid(),
            refere: action.data.tranNumber,
            currentCurrency: (action.payment.coin === "$") ? 1 : 0,
            factor: action.payment.coin === "BS" ? action.data.exchangeFactor : 1,
            // factor: action.payment.coin === "$" ? actualFactor : 1,
          }),
        }
        newTotalDebt = 0;
        newState.selectedPaymentMethods.forEach(x => {
          newTotalDebt += x.currentCurrency === 1 ? x.amount : +((x.amount / x.factor).toFixed(2))
        });
        return {
          ...state,
          ...newState,
          totalDebt: +(newTotalDebt.toFixed(2)),
        }
      } catch (error) {
        console.error(CXC_ONSUBMITPAYMENT, error)
        return state
      }

    case CXC_MODALSETVALUE:
      try {
        return {
          ...state,
          modal: {
            ...state.modal,
            [action.id]: action.value
          }
        }
      } catch (error) {
        console.error(CXC_MODALSETVALUE, error)
        return state
      }

    case CXC_SETADVANCELIST:
      try {
        return {
          ...state,
          advancePaymentList: action.data.detail.map(x => ({
            uniqueKey: uuid(),
            transAmount: x.transAmount,
            remainingAmount: x.remainingAmount,
            dateReg: moment(new Date(x.dateReg)).format("DD-MM-YYYY")
          })),
          advanceTotal: action.data.amount
        }
      } catch (error) {
        console.error(CXC_SETADVANCELIST, error)
        return state
      }

    default:
      return state;
  }
};

export default CxcReducer