import React, { memo, useState } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    List,
    ListItem,
    Paper,
    TextField,
} from "@material-ui/core";

import ListStyle from "../styles/List";
import useTextField from '../helper/UseTextField';
import { useForm } from "react-hook-form";
import { fnSearchOnData, fnSortData } from "../helper/DataModify";
import TaGeneralTitle from "../components/typography/TaGeneralTitle";

const UserList = memo(({ data, onClick, height, disabled }) => {
    const classes = ListStyle({ hover: true });
    const { handleSubmit, register, errors } = useForm();
    const txtFilter = useTextField('filter', 'Buscar usuario', errors, "text");
    const fnOnClick = (x) => {
        setSelectedUniqueKey(x.uniqueKey);
        onClick(x)
    }
    const [filter, setFilter] = useState("");
    const [selectedUniqueKey, setSelectedUniqueKey] = useState();
    const fnOnSubmit = (data) => setFilter(data.filter);
    return (
        <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TaGeneralTitle title={`Usuarios`} />
                {/* <TaTypography fontSize="16pt" align="center">{`Usuarios`}</TaTypography> */}
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <form onSubmit={handleSubmit(fnOnSubmit)}>
                    <TextField
                        {...txtFilter}
                        fullWidth
                        inputRef={register()} 
                        disabled={disabled}/>
                </form>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper style={{ padding: "5px", height: height - 275, overflow: "auto" }} elevation={0}>
                    <List
                        component="nav">
                        {data && fnSortData(fnSearchOnData(data, 'name', filter)).map(x => {
                            return (
                                <ListItem
                                    key={x.uniqueKey}
                                    classes={{ root: classes.root, selected: classes.selected }}
                                    selected={x.uniqueKey === selectedUniqueKey}
                                    onClick={(disabled) ? undefined : () => fnOnClick(x)}>
                                    <Grid container style={{ margin: "5px" }} alignItems="center" alignContent={"flex-start"} spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>{x.name}</Grid>
                                    </Grid>
                                </ListItem>
                            )
                        })}
                    </List>
                </Paper>
            </Grid>
        </Grid>
    );
});

UserList.propTypes = {
    data: PropTypes.array,
    onClick: PropTypes.func,
    height: PropTypes.number,
};

UserList.defaultProps = {
    data: [],
    onClick: {}
}

export default UserList;