import React, { useReducer } from "react";
import {
    SW_INIT,
} from "./SWConstants";
import SWReducer from "./SWReducer";
import {
    setUpdate,
} from "./SWActions";

const SWContext = React.createContext(SW_INIT);
const SWProvider = ({ children }) => {
    const [swState, dispatch] = useReducer(SWReducer, SW_INIT);
    const hasUpdate = (hasUpdate) => dispatch(setUpdate(hasUpdate));
    const swDispatch = {
        hasUpdate: hasUpdate,
    };
    return (
        <SWContext.Provider value={{ swState, swDispatch }}>
            {children}
        </SWContext.Provider>
    );
};

export { SWContext, SWProvider };