import React, { memo} from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form"

import {
    Grid,
    TextField,
} from "@material-ui/core";

import TaFab from "../components/fab/TaFab";
//import TaButton from "../components/button/TaButton";

import {
    regexDate,
} from "../helper/RegularExpressions"

import TaTypography from '../components/typography/TaTypography';
import useTextField from '../helper/UseTextField';

const CxcFilter = memo(({ onSearch, onClick, exchangeLabel, exchangeFactor, disabled }) => {

    const { handleSubmit, register, errors } = useForm();
    const iniDate = useTextField('iniDate', 'Desde', errors, 'date');
    const endDate = useTextField('endDate', 'Hasta', errors, 'date');

    const fnOnSubmit = async (data) => onSearch(data)
    return (
        <form onSubmit={handleSubmit(fnOnSubmit)}>
            <Grid container spacing={1} alignContent="center" alignItems="center">
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} style={{ minHeight: "65px" }}>
                    <TextField
                        {...iniDate}
                        fullWidth
                        disabled={disabled}
                        inputRef={register({ required: "Requerido", pattern: regexDate, })}/>
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} style={{ minHeight: "65px" }}>
                    <TextField
                        {...endDate}
                        fullWidth
                        disabled={disabled}
                        inputRef={register({ required: "Requerido", pattern: regexDate, })}/>
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2} style={{ minHeight: "65px" }}>
                    <TaFab
                        id="btnSearchCxC"
                        icon="search"
                        color="blue"
                        type="submit" 
                        disabled={disabled}/>
                </Grid>
                <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                    <TaTypography fontSize="14pt">{`Factor: ${exchangeFactor}`}</TaTypography>
                    {/* <TaTypography fontSize="14pt">{`Factor: ${exchangeFactor.formatNumb(2)}`}</TaTypography> */}
                </Grid>
            </Grid>
        </form>
    );
});

CxcFilter.propTypes = {
    onSearch: PropTypes.func,
};

export default CxcFilter;