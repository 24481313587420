import React, { memo, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    List,
    ListItem,
    Paper,
    TextField,
} from "@material-ui/core";

import ListStyle from "../styles/List";
import useTextField from '../helper/UseTextField';
import { useForm } from "react-hook-form";
//import { fnSearchOnData, fnSortData } from "../helper/DataModify";
import TaGeneralTitle from "../components/typography/TaGeneralTitle";
import TaFab from "../components/fab/TaFab";

import {
    getCustomers,
} from "./Api";

import { SnackbarContext } from "../components/snackbar/SnackbarContext";

import {
    isOK,
} from "../helper/RestStatus";
import uuid from "react-uuid";
import Loader from "../components/loader/Loader";

const CustomerList = memo(({ onClick, height, loader }) => {
    const classes = ListStyle({ hover: true, pointer: true });
    const { handleSubmit, register, errors } = useForm();
    const txtFilter = useTextField('filter', 'Buscar cliente', errors, "text");
    const [customers, setCustomers] = useState([]);    
    const [disabled, setDisabled] = useState(false)
    const [isLoading, setIsLoading] = useState(false);
    const { sbDispatch } = useContext(SnackbarContext);

    const fnOnSubmit = async(data) => {
        setIsLoading(true);
        const response = await getCustomers(data.filter);
        setIsLoading(false);
        if (isOK(response.status)) {
            if (response.data.errorCode === 0) {
                return setCustomers(response.data.data.map(x => ({ id: x.idClient, name: x.nameClient, uniqueKey: uuid() })));
            }
        }
        return sbDispatch.api(response.status, response.data);
    };

    useEffect(() => (loader === true) ? setDisabled(true) : (isLoading === true) ? setDisabled(true) : setDisabled(false), [isLoading, loader])

    return (
        <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <TaGeneralTitle title={`Clientes`} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <form onSubmit={handleSubmit(fnOnSubmit)}>
                    <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={9} md={9} lg={9} xl={10}>
                            <TextField
                                {...txtFilter}
                                fullWidth
                                inputRef={register()} 
                                disabled={disabled} />
                        </Grid>
                        <Grid item xs={12} sm={3} md={3} lg={3} xl={2}>
                            <TaFab
                                icon="search"
                                type="submit" 
                                disabled={disabled} />
                        </Grid>
                    </Grid>
                </form>
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                <Paper style={{ padding: "5px", height: height - 315, overflow: "auto" }} elevation={0}>
                    <List
                        component="nav">
                        {customers && customers.map(x => {
                            return (
                                <ListItem
                                    key={x.uniqueKey}
                                    classes={{ root: classes.root, selected: classes.selected }}
                                    onClick={() => onClick(x)}>
                                    <Grid container style={{ margin: "5px" }} alignItems="center" alignContent={"flex-start"} spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>{x.name}</Grid>
                                    </Grid>
                                </ListItem>
                            )
                        })}
                    </List>
                </Paper>
            </Grid>
            <Loader isLoading={isLoading} />
        </Grid>
    );
});

CustomerList.propTypes = {
    data: PropTypes.array,
    onClick: PropTypes.func,
    height: PropTypes.number,
};

CustomerList.defaultProps = {
    data: [],
    onClick: {}
}

export default CustomerList;