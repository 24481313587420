import axios from 'axios';

var baseUrl = process.env.NODE_ENV === 'development' ? "http://localhost:1595" : "https://wassaint.dyndns.org:45/api",
    bearerToken = "",
    authBasicHeader = {
        username: "",
        password: "",
    };

export const setBaseUrl = (value) => baseUrl = value
export const setBasicAuth = (user, pass = "") => {
    authBasicHeader = {
        username: user,
        password: pass
    }
}
export const fnGetUrl = async (apiRequest) => {
    if (apiRequest.body && process.env.NODE_ENV === 'development') {
        console.log('body: ', JSON.stringify(apiRequest.body))
    }
    if (apiRequest.useBasic) {
        return await axios.get(baseUrl + apiRequest.endpoint, {
            auth: {
                ...authBasicHeader
            }
        })
            .then(response => response)
            .catch(error => handleError(error))
    } else {
        return await axios.get(baseUrl + apiRequest.endpoint)
            .then(response => response)
            .catch(error => handleError(error))
    }

}

export const fnPostUrl = async (apiRequest) => {
    if (apiRequest.body && process.env.NODE_ENV === 'development') {
        console.log('body: ', JSON.stringify(apiRequest.body))
    }

    if (apiRequest.useBasic) {
        return await axios.post(baseUrl + apiRequest.endpoint, apiRequest.body, {
            auth: {
                ...authBasicHeader
            }
        })
            .then(response => response)
            .catch(error => handleError(error))
    } else {
        return await axios.post(baseUrl + apiRequest.endpoint, apiRequest.body)
            .then(response => response)
            .catch(error => handleError(error))
    }
}

export const fnPutUrl = async (apiRequest) => {
    if (apiRequest.body && process.env.NODE_ENV === 'development') {
        console.log('body: ', JSON.stringify(apiRequest.body))
    }

    if (apiRequest.useBasic) {
        return await axios.put(baseUrl + apiRequest.endpoint, apiRequest.body, {
            auth: {
                ...authBasicHeader
            }
        })
            .then(response => response)
            .catch(error => handleError(error))
    } else {
        return await axios.put(baseUrl + apiRequest.endpoint, apiRequest.body)
            .then(response => response)
            .catch(error => handleError(error))
    }
}

const handleError = (error) => {
    if (error.response) {
        return error.response
    } else {
        return {
            status: 503,
            data: {
                message: "No hay conexión contra el servicio web de la Academia Washington"
            }
        }
    }
}