import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import TaTable from '../components/table/TaTable'
import TaTableCell from "../components/table/TaTableCell"
import TaCheckBox from "../components/checkbox/TaCheckBox"
import {
  TableRow,
} from "@material-ui/core/";
import uuid from "react-uuid";

import TaTextFieldV2 from "../components/textfield/TaTextFieldV2"

const columnName = [
  {
    id: 0,
    label: 'Fecha',
    width: "75px",
    align: "center",
    hidden: false,
  },
  {
    id: 1,
    label: 'Recibo',
    width: "85px",
    align: "left",
    hidden: false,
  },
  {
    id: 2,
    label: 'Monto',
    width: "80px",
    align: "right",
    hidden: false,
  },
  {
    id: 3,
    label: 'Saldo',
    width: "80px",
    align: "right",
    hidden: false,
  },
  {
    id: 4,
    label: 'Diferido',
    width: "80px",
    align: "right",
    hidden: false,
  },
  {
    id: 5,
    label: 'Pagar',
    width: "80px",
    align: "right",
    hidden: false,
  },
  {
    id: 6,
    label: '',
    width: "40px",
    align: "right",
    hidden: false,
  },
];

const CxcTable = memo(({ data, onChange, height, disabled }) => {
  const tableColumn = useMemo(() => columnName.map(x => <TaTableCell key={uuid()} header={true} align={x.align} width={x.width}>{x.label}</TaTableCell>), [columnName])

  const tableBody = useMemo(() => data.map(x => {
    return (
      <TableRow key={`row-${x.uniqueKey}`}>
        <TaTableCell key={`col0-${x.uniqueKey}`} align={columnName[0].align} width={columnName[0].width}>{x.dateE}</TaTableCell>
        <TaTableCell key={`col1-${x.uniqueKey}`} align={columnName[1].align} width={columnName[1].width}>{x.numberD}</TaTableCell>
        <TaTableCell key={`col2-${x.uniqueKey}`} align={columnName[2].align} width={columnName[2].width}>{x.amountLabel.formatNumb(2)}</TaTableCell>
        <TaTableCell key={`col3-${x.uniqueKey}`} align={columnName[3].align} width={columnName[3].width}>{x.balanceLabel.formatNumb(2)}</TaTableCell>
        <TaTableCell key={`col4-${x.uniqueKey}`} align={columnName[4].align} width={columnName[4].width}>{x.referredLabel.formatNumb(2)}</TaTableCell>
        <TaTableCell key={`col5-${x.uniqueKey}`} align={columnName[5].align} width={columnName[5].width}>{
          <TaTextFieldV2
            name="paidOut"
            label=""
            fullWidth
            value={x.paidOut}
            disabled={!x.active}
            isNumeric
            align='right'
            onChange={onChange}
            uniqueKey={x.uniqueKey}
            maxValue={x.currentCurrency === 1 ? x.balanceCoinEx : x.balance} />
        }</TaTableCell>
        <TaTableCell key={`col6-${x.uniqueKey}`} align={columnName[6].align} width={columnName[6].width}>{
          <TaCheckBox
            id={`cbxActive-${x.uniqueKey}`}
            name="active"
            checked={x.active}
            onChange={onChange}
            uniqueKey={x.uniqueKey}
            key={`cbxActive-${x.uniqueKey}`}
            //AGREGADO DISABLED ADICIONAL CUANDO ESTE CARGANDO
            disabled={disabled || x.paidOut <= 0} />
        }</TaTableCell>
      </TableRow>
    )
  }), [data, columnName, onChange]);

  return (
    <TaTable
      column={tableColumn}
      body={tableBody}
      usePagination={false}
      height={`${height}px`} />
  );
});

CxcTable.propTypes = {
  data: PropTypes.array.isRequired,
  onChange: PropTypes.func,
  height: PropTypes.number,
};

export default CxcTable;