import React from 'react'
import { Grid } from '@material-ui/core'
import TaTypography from './TaTypography'

const TaGeneralTitle = ({ title }) => {
    return (
        <Grid container style={{ backgroundColor: 'var(--main-bg-color1)'}}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{height: "45px", paddingTop:"10px"}}>
                <TaTypography align='center' text={title} fontSize='18pt' color='var(--main-bg-color0)'/>
            </Grid>
        </Grid>
    )
}

export default TaGeneralTitle
