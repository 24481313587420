import React, { memo, useContext, useEffect, useState, } from 'react';
import { useForm } from "react-hook-form"

import {
  Grid,
  Link,
  Paper,
  TextField,
} from "@material-ui/core";

import { isOK, } from "../helper/RestStatus";

import {
  regexEmail,
  regexSimplePass,
} from "../helper/RegularExpressions";

import Loader from "../components/loader/Loader";
import TaButton from "../components/button/TaButton";
import {
  LoginContext,
} from "./LoginContext"

import {
  postLogin,
} from "./ApiLogin";

import { SnackbarContext } from "../components/snackbar/SnackbarContext"

import logoImg from "../img/logo.png";
import useTextField from "../helper/UseTextField";
import TaTypography from '../components/typography/TaTypography';

const Login = memo(({ onGoRegister, onRequestNewPassw, isLoading }) => {

  const { handleSubmit, register, errors, reset } = useForm();
  const [loader, setLoader] = useState(false);
  const { setLogState } = useContext(LoginContext)
  const { sbDispatch } = useContext(SnackbarContext);

  const email = useTextField('email', 'Correo', errors, "text");
  const pass = useTextField('password', 'Contraseña', errors, "password");

  const fnOnSubmit = async (data) => {
    try {
      setLoader(true)
      const response = await postLogin(data);
      setLoader(false)
      if (isOK(response.status)) {
        if (response.data.errorCode === 0) {
          return setLogState.setLogin({
            ...response.data.data,
            isLogin: true,
          })
          //return  history.push("./panel");
        }
      }
      return sbDispatch.error(response.data.message)
    } catch (error) {
      console.error('onSubmit => ', error)
    }
  }

  const fnOnGoRegister = () => {
    reset()
    onGoRegister();
  }

  const fnOnRequestNewPassw = () => onRequestNewPassw()
  //AGREGADO LOADING DURANTE LA VALIDACION DEL TOKEN
  useEffect(() => { if (isLoading) setLoader(isLoading) }, [isLoading])

  return (
    <Paper className="center" style={{ padding: "20px" }} elevation={2}>
      <Grid container alignContent="flex-start" alignItems="center" spacing={2}>
        {/* FIX DE RESPONSIVE EN xs y sm */}
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <img style={{ marginLeft: 'auto', marginRight: 'auto', align: "middle", width: "100%", height: "100%" }} src={logoImg} alt={''} />
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
          <form onSubmit={handleSubmit(fnOnSubmit)}>
            <Grid container alignContent="flex-start" alignItems="center" spacing={2}>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                <TextField
                  {...email}
                  fullWidth
                  inputRef={register({ required: "Requerido", pattern: regexEmail, })}
                  disabled={loader} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ minHeight: "80px" }}>
                <TextField
                  {...pass}
                  fullWidth
                  inputRef={register({ required: "Requerido", pattern: regexSimplePass })}
                  disabled={loader} />
              </Grid>
              <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'}>
                <TaButton
                  id={'btnLogin'}
                  label={'Ingresar'}
                  type="submit"
                  disabled={loader} />
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <TaTypography align={'center'}><Link target={'_blank'} color={'primary'} onClick={fnOnRequestNewPassw}>¿Olvido su contraseña?</Link></TaTypography>
              </Grid>
              <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                <TaTypography align={'center'}><Link target={'_blank'} color={'primary'} onClick={fnOnGoRegister}>Registrarse</Link></TaTypography>
              </Grid>
              <Loader isLoading={loader} />
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Paper>
  );
});

Login.propTypes = {

};

export default Login;