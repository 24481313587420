import {
    SW_UPDATE,
} from "./SWConstants";
const SWReducer = (state, action) => {
    switch (action.type) {
        case SW_UPDATE:
            return {
                ...state,
                hasUpdate: action.hasUpdate,
            }
        default:
            return state
    }
}
export default SWReducer;