import { fnGetUrl, fnPostUrl } from "../axios/Api";
import moment from "moment";

export const getConciliation = (dates) => {
    const filter = `?dateI=${(dates) ? dates.iniDate : moment(new Date()).format("YYYY-MM-01")}&dateF=${(dates) ? dates.endDate : moment(new Date()).format("YYYY-MM-DD")}`
    //const filter = `?idClient=${userId}`
    const apiRequest = {
        endpoint: "/Wstng/PendingPayment" + filter,
    }
    return fnGetUrl(apiRequest);
}

export const postConciliation = (data, status, comments) => {
    const body = {
        "id": data.idPayment,
        "idPaymentMethodTrans": data.idPaymentMethodTrans,
        "idClient": data.idClient,
        "Status": status,
        "Comment": status === 2 ? "Rechazado" : comments,
    }

    const apiRequest = {
        endpoint: "/Wstng/ApprovePayment/",
        body: body
    }

    return fnPostUrl(apiRequest);
}
