import { fnGetUrl, fnPostUrl } from "../axios/Api"

export const getBanks = () => {
    const apiRequest = {
        endpoint: '/PaymentMethod/PaymentMethod?bAll=true'
    }
    return fnGetUrl(apiRequest)
}

export const getBankDetail = (bankCode) => {
    const apiRequest = {
        endpoint: `/PaymentMethod/PaymentMethodDetail?bankCode=${bankCode}`
    }
    return fnGetUrl(apiRequest)
}

export const postBank = (data) => {
    const body = {...data}
    const apiRequest = {
        endpoint: `/PaymentMethod/PaymentMethod/`,
        body: body
    }
    return fnPostUrl(apiRequest)
}

export const getTypeBankAccount = () => {
    const apiRequest = {
        endpoint: `/PaymentMethod/TypeBankAccount/`,
    }
    return fnGetUrl(apiRequest)
}