import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';

import {
    TextField,
} from '@material-ui/core/';

import Autocomplete from '@material-ui/lab/Autocomplete';
import NumberFormat from 'react-number-format'

import {
    textfieldStyles,
    textfieldLabelStyles,
} from "../../styles/TextField";

const onFocus = (e) => {
    e.target.select()
}

const NumberFormatCustom = (props) => {
    const { inputRef, onChange, ...other } = props
    return (
        <NumberFormat
            {...other}
            value={other.value.toString()}
            getInputRef={inputRef}
            onValueChange={onChange}
            thousandSeparator
            decimalSeparator="."
            decimalScale={2}
        />
    );

}
const TaTextFieldV2 = React.memo((
    { id, value, name,
        label, type, placeholder,
        helperText, decimalScale, style,
        fullWidth, onBlur, align,
        onChange, options, disabled,
        maxLength, isNumeric, autoFocus,
        inputRef, uniqueKey, maxValue }
) => {
    const [val, setVal] = useState('');

    const textfieldClasses = textfieldStyles();
    const textfieldLabelClasses = textfieldLabelStyles();

    useEffect(() => {
        if (options) {
            if (value !== undefined && value !== "") {
                const currentOption = options.find(x => x.id === value)
                setVal(currentOption === undefined ? "" : currentOption.value);
            } else {
                setVal("");
            }
        } else {
            setVal(value !== undefined ? value : "");
        }
    }, []);

    useEffect(() => {
        if(maxValue){
            if(value > maxValue){
                setVal(maxValue);
            }else{
                setVal(value);
            }
           
        }
    }, [value, maxValue])

    const fnOnBlur = useCallback((event) => {
        if (onBlur) {
            onBlur(event.target.name, event.target.value, uniqueKey)
        }
    }, [])

    const fnOnKeyDown = useCallback((event) => {
        if (event.key === 'Enter') {
            event.target.blur();
        }
    }, [])

    const fnSetValue = useCallback((event, event2) => {
        let currentValue = (isNumeric) ? event.floatValue : (event2) ? event2.value : event.target.value
        currentValue = (currentValue) ? currentValue : "";
        setVal(currentValue);
        if (onChange) {
            if (options) {
                try {
                    onChange(name, event2.id, event2)
                } catch (error) {
                    onChange(name, "")
                }
            } else {
                onChange(name, currentValue, uniqueKey)
            }

        }
    }, [name, options, isNumeric])
    return (
        (options)
            ? <Autocomplete
                id={id}
                options={options}
                getOptionLabel={(option) => option.value}
                onChange={fnSetValue}
                inputValue={val}
                disabled={disabled}
                autoHighlight
                renderInput={(params) => <TextField
                    {...params}
                    label={label}
                    onChange={fnSetValue} />
                } />
            : <TextField
                id={id}
                value={val}
                name={name}
                label={label}
                type={type}
                placeholder={placeholder}
                helperText={helperText}
                fullWidth={fullWidth && fullWidth}
                onChange={fnSetValue}
                onKeyDown={fnOnKeyDown}
                onBlur={fnOnBlur}
                onFocus={onFocus}
                autoComplete={"new-password"}
                inputProps={{ maxLength: maxLength, style: { textAlign: align } }}
                disabled={disabled}
                autoFocus={(autoFocus) && autoFocus}
                InputProps={{
                    classes: textfieldClasses,
                    inputComponent: (isNumeric) && NumberFormatCustom,
                }}
                InputLabelProps={{
                    classes: textfieldLabelClasses,
                    shrink: true,
                }}
                style={(style) && style}
                inputRef={inputRef} />
    );
});

TaTextFieldV2.propTypes = {
    id: PropTypes.string,
    value: PropTypes.any,
    name: PropTypes.string,
    label: PropTypes.string,
    type: PropTypes.string,
    placeholder: PropTypes.string,
    helperText: PropTypes.string,
    decimalScale: PropTypes.number,
    style: PropTypes.object,
    align: PropTypes.string,
    options: PropTypes.array,
    onChange: PropTypes.func,
    disabled: PropTypes.bool,
    maxLength: PropTypes.number,
};

TaTextFieldV2.defaultProps = {
    name: '',
    label: '',
    type: 'text',
    placeholder: '',
    decimalScale: 2,
    align: 'left',
    disabled: false,
    maxLength: 60,
};

export default TaTextFieldV2;