import React, { memo, useState, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form"

import {
  Grid,
  Paper,
  TextField,
} from "@material-ui/core";
import Loader from "../components/loader/Loader";
import TaButton from "../components/button/TaButton";

import {
  regexPass,
  regexEmail,
  regexCompanyName,
  regexCompanyId,
  regexAddress,
  regexPhone,
  regexName,
} from "../helper/RegularExpressions";
import useTextField from "../helper/UseTextField";
import { postRegister } from "./ApiRegister";
import { SnackbarContext } from "../components/snackbar/SnackbarContext";
import { isOK, } from "../helper/RestStatus";
import useWindowSize from '../helper/UseWindowsSize';
import TaGeneralTitle from '../components/typography/TaGeneralTitle';
import { TrafficOutlined } from '@material-ui/icons';

const Register = memo(({ onReturnLogin }) => {
  const [loader, setLoader] = useState(false);
  const { handleSubmit, register, errors, reset, getValues, } = useForm();
  const { sbDispatch } = useContext(SnackbarContext);
  const size = useWindowSize()
  const [height] = useState(size.height)
  const [heightF, setHeightF] = useState(height - 237)

  const customerId = useTextField('idClient', 'Cédula/RIF *', errors, "text");
  const customerName = useTextField('name', 'Representante/Empresa *', errors, "text");
  const address = useTextField('address', 'Dirección *', errors, "text");
  const email = useTextField('email', 'Correo electrónico *', errors, "text");
  const phone = useTextField('phone', 'Teléfono *', errors, "text");
  const family = useTextField('family', 'Familia(s) *', errors, "text", 360);
  const pass = useTextField('pass', 'Contraseña *', errors, "password");
  const confirmPass = useTextField('confirmPass', 'Confirmar contraseña *', errors, "password");

  const fnOnReturn = () => {
    onReturnLogin();
  }

  const fnOnSubmit = async (data) => {
    try {
      setLoader(true)
      const response = await postRegister(data);
      setLoader(false)
      sbDispatch.api(response.status, response.data);
      if (isOK(response.status)) {
        if (response.data.errorCode === 0) {
          reset()
        }
      }
    } catch (error) {
      console.error('onSubmit => ', error)
      return sbDispatch.error('Error en el proceso de registro');
    }
  }

  //VIGILA SI HAY ERRORES EN EL FORMULARIO, SI HAY ERRORES
  //REDIMENCIONA EL CONTENEDOR
  useEffect(() => { 
    ((errors.idClient !== undefined, 
      errors.name !== undefined, 
      errors.address !== undefined, 
      errors.email !== undefined, 
      errors.phone !== undefined, 
      errors.family !== undefined, 
      errors.pass !== undefined, 
      errors.confirmPass !== undefined)) ? setHeightF(height - 166) : setHeightF(height - 237)
  }, [errors])
  return (
    <Paper className="center" style={{ padding: "20px", overflowX: 'hidden', overflowY: 'hidden !important', height: heightF }} elevation={2}>
      {/* <Grid container alignContent="flex-start" alignItems="center" spacing={1}> */}
      {/* <Grid item xs={false} sm={2} md={3} lg={3} xl={4}>
        </Grid> */}
      {/* <Grid item xs={12} sm={8} md={6} lg={6} xl={4}> */}
      <form onSubmit={handleSubmit(fnOnSubmit)}>
        <Grid container alignContent="flex-start" alignItems="stretch" spacing={2}>
          {/* AGREGADO TITULO DEL FORMULARIO */}
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TaGeneralTitle title={`Registro de usuario`} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField {...email} fullWidth inputRef={register({ required: "Requerido", pattern: regexEmail, })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField {...customerId} fullWidth inputRef={register({ required: "Requerido", pattern: regexCompanyId, })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField {...customerName} fullWidth inputRef={register({ required: "Requerido", pattern: regexCompanyName, })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField {...address} fullWidth inputRef={register({ required: "Requerido", pattern: regexAddress, })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField {...phone} fullWidth inputRef={register({ required: "Requerido", pattern: regexPhone, })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField {...family} fullWidth inputRef={register({ required: "Requerido", })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField {...pass} fullWidth inputRef={register({ required: "Requerido", pattern: regexPass, })} />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <TextField {...confirmPass} fullWidth inputRef={register({
              required: "Requerido", pattern: regexPass,
              validate: value => (getValues("pass") === value) || "Las claves no coinciden"
            })} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
            <TaButton
              label={'Regresar'}
              onClick={fnOnReturn}
              disabled={loader} />
            <TaButton
              label={'Registrarse'}
              type="submit"
              disabled={loader} />
          </Grid>
          <Loader isLoading={loader} />
        </Grid>
      </form>
      {/* </Grid> */}
      {/* <Grid item xs={false} sm={2} md={3} lg={3} xl={4}>
        </Grid> */}
      {/* </Grid> */}
    </Paper>
  );
});

Register.propTypes = {

};

export default Register;