import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { SWProvider } from "./components/ServiceWorker/SWContext";
import * as serviceWorker from './serviceWorker';

Number.prototype.formatNumb = function (n, x) {
  var re1 = new RegExp('^-?\\d+(?:\.\\d{0,' + (n || -1) + '})?');
  let newNumber = +(this.toString().match(re1)[0]);
  var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\.' : '$') + ')';
  return newNumber.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&,');
}
ReactDOM.render(
  <React.StrictMode>
    <SWProvider>
      <App serviceWorker={serviceWorker}/>
    </SWProvider>
  </React.StrictMode>,
  document.getElementById('root')
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();