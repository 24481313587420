import { Grid, Paper, TextField } from '@material-ui/core'
import React, { memo, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import TaButton from '../components/button/TaButton'
import Loader from '../components/loader/Loader'
import { SnackbarContext } from '../components/snackbar/SnackbarContext'
import TaGeneralTitle from '../components/typography/TaGeneralTitle'
import { regexPass } from '../helper/RegularExpressions'
import { isOK } from '../helper/RestStatus'
import useTextField from '../helper/UseTextField'
import { putChangePassword } from './newPasswordApi'

const NewPasswordForm = memo(({ onReturn, userId }) => {
  const { sbDispatch } = useContext(SnackbarContext)
  const { handleSubmit, register, errors, getValues, reset } = useForm()
  const pass = useTextField('pass', 'Contraseña *', errors, "password")
  const confirmPass = useTextField('confirmPass', 'Confirmar contraseña *', errors, "password")
  const [loader, setLoader] = useState(false)

  const fnOnSubmit = async (data) => {
    try {
      setLoader(true)
      const response = await putChangePassword(data, userId)
      setLoader(false)
      if (isOK(response.status)) {
        if (response.data.errorCode === 0) {
          reset()
          onReturn()
        }
      }
      return sbDispatch.api(response.status, response.data)
    } catch (error) {
      setLoader(false)
      console.error(`fnOnSubmit => ${error}`)
    }
  }
  return (
    <Paper className={'center'} elevation={1} style={{ padding: '5px', width: '45%' }}>
      <form onSubmit={handleSubmit(fnOnSubmit)}>
        <Grid container>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TaGeneralTitle title={'Ingrese su nueva contraseña'} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper elevation={0} style={{ padding: '15px' }}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField {...pass} disabled={loader} fullWidth inputRef={register({ required: "Requerido", pattern: regexPass, })} />
                </Grid>
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                  <TextField
                    {...confirmPass}
                    fullWidth
                    disabled={loader}
                    inputRef={register({
                      required: "Requerido", pattern: regexPass,
                      validate: value => (getValues("pass") === value) || "Las claves no coinciden"
                    })} />
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'}>
            <TaButton
              id={'btnReturn'}
              backgroundColor={'var(--main-bg-color5)'}
              label={'Regresar'}
              disabled={loader}
              onClick={onReturn}
            />
            <TaButton
              id={'btnChangePass'}
              backgroundColor={'var(--main-bg-color4)'}
              label={'Aceptar'}
              disabled={loader}
              type={'submit'}
            />
          </Grid>
          <Loader isLoading={loader} />
        </Grid>
      </form>
    </Paper>
  )
})

export default NewPasswordForm
