import {
    CONCILIATION_SETCONCILIATION,
    CONCILIATION_ONCHANGETABLE,
    CONCILIATION_RESET,
    CONCILIATION_INITIALSTATE,
} from "./ConciliationConstants";

import uuid from "react-uuid";
import moment from "moment";
//MODIFICADO POR Reinaldo Zambrano
// Agregados bloques try catch para control de errores y prevencion de pantallas en blanco.
const ConciliationReducer = (state, action) => {
    switch (action.type) {

        case CONCILIATION_SETCONCILIATION:
            try {
                return {
                    ...state,
                    conciliation: action.data.map(x => ({
                        ...x,
                        uniqueKey: uuid(),
                        active: false,
                        dateReg: moment(new Date(x.dateReg)).format("DD-MM-YYYY"),
                        datePay: moment(new Date(x.datePay)).format("DD-MM-YYYY"),
                        auditAmount: (x.coin === 'BS') ? x.amount : x.amountCoinEx,
                    })),
                }
            } catch (error) {
                console.error(CONCILIATION_SETCONCILIATION, error)
                return state
            }

        case CONCILIATION_ONCHANGETABLE:
            try {
                return {
                    ...state,
                    conciliation: state.conciliation.map(x => x.uniqueKey !== action.uniqueKey
                        ? (x.active) ? ({ ...x, active: false }) : x
                        : ({ ...x, [action.name]: action.value }))
                }
            } catch (error) {
                console.error(CONCILIATION_ONCHANGETABLE, error)
                return state
            }

        case CONCILIATION_RESET:
            try {
                return {
                    ...CONCILIATION_INITIALSTATE,
                }
            } catch (error) {
                console.error(CONCILIATION_RESET, error)
                return state
            }

        default:
            return state;
    }

};

export default ConciliationReducer;