import { Paper } from '@material-ui/core'
import React, { memo, useMemo, useState } from 'react'
import TaDialog from '../components/dialog/TaDialog'
import TaTabs from '../components/tabs/TaTabs'
import PaymentMethods from '../PaymentMethods'
import CashIcon from '@material-ui/icons/MonetizationOn'
import List from '@material-ui/icons/ListAlt'
import uuid from 'react-uuid'
import AdvancePaymentList from './AdvancePaymentList'
import Loader from '../components/loader/Loader'

const AdvancePaymentModal = memo(({ isOpen, onClose, data, onSubmit,
  advancePaymentList, height, advanceTotal, heightList, isLoading }) => {
  const [tabIndex, setTabIndex] = useState(0)

  const labelArray = useMemo(() => [
    {
      label: 'Agregar Anticipos',
      icon: <CashIcon />,
      hidden: false,
    },
    {
      label: 'Consultar Anticipos',
      icon: <List />,
      hidden: false,
    }
  ], [])

  const bodyArray = useMemo(() => {
    return [
      <PaymentMethods
        key={uuid()}
        data={data}
        onSubmit={onSubmit}
        totalPaidOut={0}
        totalDebt={0}
        isAdvance={true} 
        isLoading={isLoading} />,
      <AdvancePaymentList key={uuid()} data={advancePaymentList} advanceTotal={advanceTotal} heightList={heightList} />
    ]
  }, [data, advancePaymentList, advanceTotal, onSubmit, heightList, isLoading])

  const fnOnChangeTab = (value) => setTabIndex(value)

  return (
    <TaDialog
      id={'modalAdvPaymnt'}
      isOpen={isOpen}
      onClose={onClose}
      maxWidth={'xs'}
      content={
        <Paper elevation={0} style={{ height: height }}>
          <TaTabs
            tabIndex={tabIndex}
            onChange={fnOnChangeTab}
            labelArray={labelArray}
            bodyArray={bodyArray}
          />
          <Loader isLoading={isLoading} />
        </Paper>
      }
    />
  )
})

export default AdvancePaymentModal