import { Grid, Paper } from '@material-ui/core'
import React, { memo, useContext, useEffect, useReducer, useState } from 'react'
import Loader from '../components/loader/Loader'
import useWindowSize from '../helper/UseWindowsSize'
import BankList from './BankList'
import { getBankDetail, getBanks, getTypeBankAccount, postBank } from './BankApi'
import { isOK } from '../helper/RestStatus'
import { SnackbarContext } from '../components/snackbar/SnackbarContext'
import BankInformation from './BankInformation'
import uuid from 'react-uuid'

const initialBank = {
  "id": -100,
  "bankCode": "",
  "bankName": "",
  "rif": "",
  "accountName": "",
  "accountNumber": "",
  "idTypeBankAccount": 0,
  "coin": "",
  "active": false
}
const Bank = memo(({ userId }) => {
  const { sbDispatch } = useContext(SnackbarContext)
  const size = useWindowSize()
  const [loader, setLoader] = useState(false)
  const [heightM, setHeightM] = useState('')
  const [heightL, setHeightL] = useState('')
  const [heightB, setHeightB] = useState('')
  const [currentBank, setCurrentBank] = useState(initialBank)
  const [accTypeList, setAccTypeList] = useState([])
  const { height } = size
  const [banks, setBanks] = useState([])

  const fnGetInitialValues = async () => {
    await fnGetBankList()
    await fnGetTypeBankAccount()
  }
  //AGREGADA FUNCION PARA RESET DE LOS CAMPOS CUANDO SE REALIZA EL POST DE BANCOS
  const fnResetUi = () => {
    setLoader(false)
    setCurrentBank(initialBank)
  }

  const fnGetBankList = async () => {
    try {
      setLoader(true)
      let response = await getBanks()
      setLoader(false)
      if (isOK(response.status)) {
        if (response.data.errorCode === 0) {
          return setBanks(response.data.data.map(x => ({ ...x, uniqueKey: uuid() })))
        }
      }
      return sbDispatch.error(response.data.message)
    } catch (error) {
      setLoader(false)
      console.error(`fnGetBankList => ${error}`)
    }
  }

  const fnGetBankDetail = async (bankCode) => {
    try {
      setLoader(true)
      setCurrentBank(initialBank)
      let response = await getBankDetail(bankCode)
      setLoader(false)
      if (isOK(response.status)) {
        if (response.data.errorCode === 0) {
          return setCurrentBank({ ...response.data.data, idUserRegister: userId })
        }
      }
      return sbDispatch.error(response.data.message)
    } catch (error) {
      setLoader(false)
      console.error(`fnGetBankDetail => ${error}`)
    }
  }

  const fnPostBank = async (data) => {
    try {
      setLoader(true)
      const response = await postBank(data)
      setLoader(false)
      sbDispatch.api(response.status, response.data)
      if (isOK(response.status)) {
        if (response.data.errorCode === 0) {
          return fnResetUi()
        }
      }
    } catch (error) {
      setLoader(false)
      console.error(`fnPostBank => ${error}`)
    }
  }

  const fnGetTypeBankAccount = async () => {
    try {
      setLoader(true)
      let response = await getTypeBankAccount()
      setLoader(false)
      if (isOK(response.status)) {
        if (response.data.errorCode === 0) {
          return setAccTypeList(response.data.data.map(x => ({ ...x, uniqueKey: uuid() })))
        }
      }
      return sbDispatch.error(response.data.message)
    } catch (error) {
      setLoader(false)
      console.error(`fnGetTypeBankAccount => ${error}`)
    }
  }

  const fnOnClick = (bankCode) => fnGetBankDetail(bankCode)

  useEffect(() => { fnGetInitialValues() }, [])

  useEffect(() => {
    setHeightM(height - 116)
    setHeightL(height - 129)
    setHeightB(height - 187)
  }, [height])

  return (
    <Paper id={'bankPaper1'} elevation={2} style={{ padding: '5px', height: heightM }}>
      <Grid container spacing={1} alignContent={'flex-start'} alignItems={'flex-start'}>
        <Grid item xs={12} sm={12} md={5} lg={5} xl={5}>
          <Paper id={'bankListPaper'} elevation={1} style={{ padding: '5px', height: heightL, }}>
            <BankList heightB={heightB} data={banks} onClick={fnOnClick} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={7} lg={7} xl={7}>
          <Paper id={'bankInfoPaper'} elevation={1} style={{ padding: '5px', height: heightL }}>
            <BankInformation
              height={heightL}
              data={currentBank}
              disabled={currentBank.id === -100 || loader}
              setCurrentBank={setCurrentBank}
              isLoading={loader}
              accTypeList={accTypeList}
              fnPostBank={fnPostBank} />
          </Paper>
        </Grid>
      </Grid>
      <Loader isLoading={loader} />
    </Paper>
  )
})

Bank.propTypes = {}

export default Bank