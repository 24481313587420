import React, { memo, useEffect, useReducer, useState, useContext } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Paper,
} from "@material-ui/core";

import CxcReducer from "./CxcReducer"
import Loader from "../components/loader/Loader"

import {
    setPaymentMethods,
    setCxc,
    setOnChangeTable,
    resetUI,
    setExchangeFactor,
    setCurrency,
    delSelectedPaymentMethod,
    onSubmitPayment,
    openModal,
    closeModal,
    setAdvanceList,
} from "./CxcActions";

import {
    getPaymentMethods,
    getCxC,
    postCxcPayment,
    getFactor,
    postAdvancePayment,
    getAdvanceList,
} from "./ApiCxc"

import { SnackbarContext } from "../components/snackbar/SnackbarContext"

import {
    isOK,
} from "../helper/RestStatus";

import CxcFilter from './CxCFilter'
import CxcTable from "./CxcTable";
import CxcResume from "./CxcResume";
import PaymentMethods from "../PaymentMethods/";
import SelectedPaymentMethods from "../PaymentMethods/SelectedPaymentMethods"

import {
    CXC_INITIALSTATE,
} from "./CxcConstants";

import useWindowSize from "../helper/UseWindowsSize";
import TaFab from "../components/fab/TaFab";
import TaTypography from "../components/typography/TaTypography";
import AdvancePaymentModal from './AdvancePaymentModal';
import { LoginContext } from '../Login/LoginContext';

const Cxc = memo(({ userId, userType }) => {
    const [state, dispatch] = useReducer(CxcReducer, CXC_INITIALSTATE);
    const { loginState } = useContext(LoginContext)
    const [loader, setLoader] = useState(false);
    const [heightT, setHeightT] = useState("");
    const [heightP, setHeightP] = useState("");
    const [heightM, setHeightM] = useState("");
    const [heightL, setHeightL] = useState("");
    const { sbDispatch } = useContext(SnackbarContext);
    const { cxc, totalBalance, totalPaidOut,
        paymentMethods, selectedPaymentMethods, totalDebt,
        totalDeferred, exchangeLabel, totalBalanceLabel,
        totalPaidOutLabel, totalDeferredLabel, exchangeFactor, modal,
        advancePaymentList, advanceTotal, } = state
    const { idClient, id, name } = loginState

    const size = useWindowSize();

    const { height } = size;

    const fnInitialValues = async () => {
        setLoader(true)
        await fnGetFactor();
        await fnGetPaymentMethods();
        await fnGetAdvanceList();
        setLoader(false)
    }

    const fnGetPaymentMethods = async () => {
        // setLoader(true);
        let response = await getPaymentMethods();
        // setLoader(false);
        if (isOK(response.status)) {
            if (response.data.errorCode === 0) {
                dispatch(setPaymentMethods(response.data.data))
                return fnOnSearch();
            }
        }
        return sbDispatch.error(response.data.message);
    }

    const fnOnSearch = async (data) => {
        if (loader === true) {
            const response = await getCxC(userId, data);
            if (isOK(response.status)) {
                if (response.data.errorCode === 0) {
                    return dispatch(setCxc(response.data.data))
                }
            }
            return sbDispatch.error(response.data.message);
        } else {
            setLoader(true);
            const response = await getCxC(userId, data);
            setLoader(false);
            if (isOK(response.status)) {
                if (response.data.errorCode === 0) {
                    return dispatch(setCxc(response.data.data))
                }
            }
            return sbDispatch.error(response.data.message);
        }
    }

    const fnGetFactor = async () => {
        let response = await getFactor();
        if (isOK(response.status)) {
            if (response.data.errorCode === 0) {
                dispatch(setExchangeFactor(response.data.data))
                return fnOnSearch();
            }
        }
        return sbDispatch.error(response.data.message);
    }

    const fnOnChangeTable = (name, value, uniqueKey) => dispatch(setOnChangeTable(name, value, uniqueKey));
    const fnOnSave = async () => {
        if (totalPaidOut === 0) {
            return sbDispatch.warning('Seleccione una factura para realizar el pago');
        } else if (totalPaidOut > totalDebt) {
            return sbDispatch.warning('Debe pagar el total de la deuda');
        } else if (totalPaidOut < totalDebt) {
            return sbDispatch.warning('El monto a pagar supera la deuda pendiente');
        }
        setLoader(true);
        const response = await postCxcPayment(idClient, id, state, name, exchangeFactor)
        setLoader(false);
        sbDispatch.api(response.status, response.data)
        if (isOK(response.status)) {
            if (response.data.errorCode === 0) {
                dispatch(resetUI());
            }
        }
    }

    const fnOnDelete = (id, uniqueKey) => dispatch(delSelectedPaymentMethod(uniqueKey));

    const fnOnClickExchangeFactor = () => dispatch(setCurrency());

    const fnOnSubmitPayment = (payment, data) => dispatch(onSubmitPayment(payment, data))

    // CREADO POR REINALDO ZAMBRANO
    // FUNCIONES DE ABRIR Y CERRAR MODAL DE ANTICIPOS
    const fnOnClickOpenModal = () => dispatch(openModal())
    const fnCloseModal = () => dispatch(closeModal())

    // CREADO POR REINALDO ZAMBRANO
    // FUNCION PARA POST DE ANTICIPOS
    // NO REMOVER EL PARAMETRO PAYMENT DE LA FUNCION
    const fnSubmitAdvance = async (payment, data) => {
        setLoader(true)
        const response = await postAdvancePayment(idClient, id, data)
        setLoader(false)
        await fnGetAdvanceList()
        return sbDispatch.api(response.status, response.data)
    }

    // CREADO POR REINALDO ZAMBRANO
    // FUNCION PARA RECIBIR LA LISTA DE ANTICIPOS
    const fnGetAdvanceList = async () => {
        // setLoader(true);
        let response = await getAdvanceList(idClient);
        // setLoader(false);
        if (isOK(response.status)) {
            if (response.data.errorCode === 0) {
                dispatch(setAdvanceList(response.data.data))
            }
        }
    }

    useEffect(() => {
        fnInitialValues();
        // fnGetPaymentMethods();
        // fnGetFactor();
        // setHeightT(size.height - 350);
        // setHeightP(size.height - 280);
    }, []);

    useEffect(() => {
        setHeightT(height - 350);
        setHeightP(height - 280);
        // AJUSTE DE LA ALTURA DEL MODAL DE ANCITIPOS
        setHeightM(height - 185)
        setHeightL(height - 325)
    }, [height])

    return (
        <Paper style={{ padding: "5px" }}>
            <Grid container alignContent="flex-start" alignItems="flex-start" spacing={2}>
                <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                    <Paper style={{ padding: "5px" }} elevation={0}>
                        <CxcFilter onSearch={fnOnSearch} onClick={fnOnClickExchangeFactor} exchangeLabel={exchangeLabel} exchangeFactor={exchangeFactor} disabled={loader} />
                    </Paper>
                </Grid>
                <Grid item xs={false} sm={5} md={5} lg={5} xl={5}>
                </Grid>
                <Grid item xs={12} sm={7} md={7} lg={7} xl={7}>
                    {/* Agregado disabled al checkbox de la lista de documentos */}
                    <CxcTable data={cxc} onChange={fnOnChangeTable} height={heightT} disabled={loader} />
                    <Paper style={{ padding: "5px", marginTop: "5px" }} elevation={0}>
                        <CxcResume
                            totalBalance={totalBalanceLabel}
                            totalPaidOut={totalPaidOutLabel}
                            totalDeferred={totalDeferredLabel}
                            onClickOpenModal={fnOnClickOpenModal}
                            disabled={loader} />
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={5} md={5} lg={5} xl={5}>
                    <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
                        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
                            <PaymentMethods
                                data={paymentMethods}
                                onSubmit={fnOnSubmitPayment}
                                height={heightP}
                                totalPaidOut={totalPaidOut}
                                totalDebt={totalDebt}
                                isAdvanse={false}
                                exchangeFactor={exchangeFactor}
                                //AGREGADO DISABLED
                                isLoading={loader} />
                        </Grid>
                        <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
                            <SelectedPaymentMethods
                                data={selectedPaymentMethods}
                                height={heightP}
                                onClick={fnOnDelete}
                                //AGREGADO DISABLED
                                isLoading={loader} />
                        </Grid>
                        <Grid item xs={8} sm={8} md={8} lg={8} xl={8}>
                            <TaTypography fontSize="14pt" align='right'>{`Saldo:`}</TaTypography>
                            <TaTypography fontSize="14pt" align='right'>{`Pagado:`}</TaTypography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2}>
                            <TaTypography fontSize="14pt" align='right'>{`${(totalPaidOut - totalDebt).formatNumb(2)}`}</TaTypography>
                            <TaTypography fontSize="14pt" align='right'>{`${totalDebt.formatNumb(2)}`}</TaTypography>
                        </Grid>
                        <Grid item xs={2} sm={2} md={2} lg={2} xl={2} align='right'>
                            <TaFab
                                icon="done"
                                color="green"
                                onClick={fnOnSave}
                                //AGREGADO DISABLED
                                disabled={loader} />
                        </Grid>
                    </Grid>
                </Grid>
                {/* MODIFICADO POR Reinaldo Zambrano */}
                {/* AGREGADO UN COMPONENTE DE TIPO MODAL PARA REGISTAR ANTICIPOS */}
                <AdvancePaymentModal
                    isOpen={modal.isOpen}
                    onClose={fnCloseModal}
                    data={paymentMethods}
                    onSubmit={fnSubmitAdvance}
                    advancePaymentList={advancePaymentList}
                    height={heightM}
                    advanceTotal={advanceTotal}
                    heightList={heightL}
                    isLoading={loader} />
                <Loader isLoading={loader} />
            </Grid>
        </Paper>
    );
});

Cxc.propTypes = {
    userId: PropTypes.string,
    userType: PropTypes.number,
};

Cxc.defaultProps = {
    userType: 0,
}

export default Cxc;