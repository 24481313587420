import { Grid, TextField } from '@material-ui/core'
import React, { memo } from 'react'
import { useForm } from 'react-hook-form'
import TaButton from '../components/button/TaButton'

const UserBottomBar = memo(({ txtCommentary, onSave, disabled, hidden }) => {
    const { handleSubmit, register } = useForm()
    return (
        <form onSubmit={handleSubmit(onSave)}>
            <Grid container>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'right'}>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4} align={'right'} >
                    <TextField {...txtCommentary} fullWidth disabled={disabled || hidden} inputRef={register({ minLength: 5 })}/>
                </Grid>
                <Grid item xs={4} sm={4} md={4} lg={4} xl={4}>
                    <TaButton
                        id="btnRejectUser"
                        label={'Rechazar'}
                        type={'submit'}
                        backgroundColor="var(--main-bg-color5)"
                        // onClick={fnOnSave}
                        disabled={disabled} />
                    <TaButton
                        id="btnApproveUser"
                        label={'Aprobar'}
                        type={'submit'}
                        backgroundColor="var(--main-bg-color4)"
                        // onClick={fnOnSave}
                        disabled={disabled} />
                </Grid>
            </Grid>
        </form>
    )
})

export default UserBottomBar
