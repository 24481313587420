import {
    AUTHORIZE_INITIALSTATE,
    AUTHORIZE_SETVALUE,
    AUTHORIZE_OPEN,
    AUTHORIZE_CLOSE,
} from "./AuthorizeConstants"

const AuthorizeReducer = (state, action) => {
    switch (action.type) {
        case AUTHORIZE_SETVALUE:
            return {
                ...state,
                [action.id]: action.value
            }
        case AUTHORIZE_OPEN:
            return {
                ...state,
                isOpen: true,
                title: action.title,
                authType: action.authType,
                data: action.data,
                onAccept: action.onAccept,
                onDeny: action.onDeny,
            }
        case AUTHORIZE_CLOSE:
            return {
                ...AUTHORIZE_INITIALSTATE
            }
        default:
            return state;
    }
}

export default AuthorizeReducer