export const TableHeader = {
    header: {
        backgroundColor: 'var(--main-bg-color1)',
        color: 'var(--main-bg-color0)',
        fontSize: '14pt',
        fontFamily: "Archivo",
        position: "sticky",
        top: 0,
        zIndex: 10,
        padding: '5px 0px',
    }
}