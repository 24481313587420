import { fnGetUrl, fnPostUrl, fnPutUrl } from "../axios/Api"

export const getUsers = (status) => {
    const apiRequest = {
        endpoint: `/Wstng/User/?isAll=false&active=${status}`,
    }
    return fnGetUrl(apiRequest);
}

export const getUserDetail = (id) => {
    const apiRequest = {
        endpoint: `/Wstng/UserDetail?idUser=${id}`,
    }
    return fnGetUrl(apiRequest);
}

export const getCustomers = (name) => {
    const apiRequest = {
        endpoint: `/Wstng/Client?isAll=true&name=${name}`,
    }
    return fnGetUrl(apiRequest);
}

//MODIFICADO POR Reinaldo Zambrano
//Añadido typeUser como valor obtenido desde data
//Añadido parametro opcional commentary
export const putUser = (userId, status, data, commentary = '') => {
    const body = {
        "idClient": data.idClient,
        "name": data.name,
        "address": data.address,
        "email": data.email,
        "typeUser": data.typeUser,
        "phone": data.phone,
        "active": status,
        "idUserRegister": userId,
        "commentary": commentary,
        "password": "",
        "client": data.client.map(x => ({ idClient: x.id }))
    }

    const apiRequest = {
        endpoint: `/Wstng/User/${data.idUser}`,
        body: body
    }
    return fnPutUrl(apiRequest)
}