import { fnPostUrl, fnPutUrl } from "../axios/Api"

//AGREGADO POST DE ENVIO DE CORREO PARA SOLICITUD DE CAMBIO DE CONTRASEÑA
export const postValidateEmail = (data) => {
    const apiRequest = {
        endpoint: '/Wstng/ValEmailChangePass/',
        body: data
    }
    return fnPostUrl(apiRequest)
}

//AGREGADO PUT DE CAMBIO DE CONTRASEÑA
export const putChangePassword = (data, userId) => {
    const body = {
        id: userId,
        password: data.pass
    }
    const apiRequest = {
        endpoint: '/Wstng/ChangePassword/',
        body: body
    }
    return fnPutUrl(apiRequest)
}