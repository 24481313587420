import { fnGetUrl, fnPostUrl } from "../axios/Api"
import moment from "moment";

export const getPaymentMethods = () => {
    const apiRequest = {
        endpoint: "/PaymentMethod/PaymentMethod?bAll=true",
    }

    return fnGetUrl(apiRequest);
}

export const getCxC = (userId, dates) => {
    const filter = `?idUser=${userId}&dateI=${(dates) ? dates.iniDate : moment(new Date()).format("YYYY-MM-01")}&dateF=${(dates) ? dates.endDate : moment(new Date()).format("YYYY-MM-DD")}`
    //const filter = `?idClient=${userId}`

    const apiRequest = {
        endpoint: "/Wstng/PendingInvoice" + filter,
    }
    return fnGetUrl(apiRequest);
}

export const postCxcPayment = (idClient, id, data, name, exchangeFactor) => {
    try {
        const currentCxc = data.cxc.find(x => (x.active))
        // MODIFICADO POR Reinaldo Zambrano
        // MODIFICACION DEL MODELO DE POST PARA DECLARACION DE PAGOS SEGUN CAMBIOS DEN BACKEND
        // ENCERRADO EN BLOQUE TRY CATCH PARA CONTROL DE ERRORES
        const body = {
            "idUser": id,
            "idClient": currentCxc.idClient,
            "invoiceType": currentCxc.invoiceType,
            "total": data.totalDebt,
            "numberD": currentCxc.numberD,
            "bOffice": currentCxc.bOffice,
            "amount": currentCxc.currentCurrency === 0 ? currentCxc.amount : +(currentCxc.amount / exchangeFactor).toFixed(2),
            "nameUser": name,
            "nameClient": currentCxc.name,
            "paymentStatementMethod": data.selectedPaymentMethods.map(x => ({
                "idPayment": 0,
                "bankCode": x.id,
                "bankName": x.name,
                // "cardCod": x.cardCode,
                "numberTrans": x.refere,
                "amount": x.currentCurrency === 0 ? x.amount : +(x.amount * x.factor).toFixed(2),
                "factor": x.currentCurrency === 0 ? x.factor : 1,
                "amountCoinEx": x.currentCurrency === 1 ? x.amount : +(x.amount / x.factor).toFixed(2),
                "coin": x.coin,
                "dateTrans": x.date,
            }))
        }
        const apiRequest = {
            endpoint: "/Wstng/PaymentStatement/",
            body: body
        }
        return fnPostUrl(apiRequest);
    } catch (error) {
        console.error(`postCxcPayment => ${error}`)
        return null
    }
}

export const getFactor = () => {
    const apiRequest = {
        endpoint: "/Wstng/Factor",
    }

    return fnGetUrl(apiRequest);
}

// AGREGADO POR Reinaldo Zambrano
// Agregada funcion para el POST de Anticipos
export const postAdvancePayment = (idClient, id, data) => {
    const body = {
        idClient: idClient,
        idUser: id,
        // date: data.tranDate,
        numberD: data.tranNumber,
        amount: data.amount,
        sign: '+'
    }
    const apiRequest = {
        endpoint: "/api/Advance/Advance/",
        body: body
    }
    return fnPostUrl(apiRequest);
}

//AGREGADO POR Reinaldo Zambrano
//Agregada funcion para el GET de Listado de anticipos
export const getAdvanceList = (userId, dates) => {
    const apiRequest = { endpoint: `/api/Advance/Advance?idClient=${userId}` }
    return fnGetUrl(apiRequest);
}