import {
    CXC_SETPAYMENTMETHODS,
    CXC_SET,
    CXC_ONCHANGETABLE,
    CXC_SELECTEDPAYMENTMETHOD,
    CXC_ONCHANGEPAYMENT,
    CXC_RESETUI,
    CXC_SETFACTOR,
    CXC_CHANGECURRENCY,
    CXC_DELSELECTEDPAYMENTMETHOD,
    CXC_ONSUBMITPAYMENT,
    CXC_MODALSETVALUE,
    CXC_SETADVANCELIST,
} from "./CxcConstants";

export const setPaymentMethods = (data) => ({ type: CXC_SETPAYMENTMETHODS, data });
export const setCxc = (data) => ({ type: CXC_SET, data });
export const setOnChangeTable = (name, value, uniqueKey) => ({ type: CXC_ONCHANGETABLE, name, value, uniqueKey });
export const selectedPaymentMethod = (data) => ({ type: CXC_SELECTEDPAYMENTMETHOD, data });
export const setOnChangePayment = (name, value, uniqueKey) => ({ type: CXC_ONCHANGEPAYMENT, name, value, uniqueKey });
export const resetUI = () => ({ type: CXC_RESETUI });
export const setExchangeFactor = (data) => ({ type: CXC_SETFACTOR, data });
export const setCurrency = () => ({ type: CXC_CHANGECURRENCY });
export const delSelectedPaymentMethod = (uniqueKey) => ({ type: CXC_DELSELECTEDPAYMENTMETHOD, uniqueKey });
export const onSubmitPayment = (payment, data) => ({ type: CXC_ONSUBMITPAYMENT, payment, data })
export const openModal = () => ({ type: CXC_MODALSETVALUE, id: 'isOpen', value: true })
export const closeModal = () => ({ type: CXC_MODALSETVALUE, id: 'isOpen', value: false })
export const setAdvanceList = (data) => ({ type: CXC_SETADVANCELIST, data })