import React, { memo, useState, useCallback, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import uuid from "react-uuid"
import {
    Collapse,
    List,
    ListItem,
    Popover,
} from "@material-ui/core"

import TaFab from "../fab/TaFab"
import TaTypography from "../typography/TaTypography"
//import useSpKeys from "helper/UseSpKeys";
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import ListStyle from "../../styles/List";
import { fnMinIndex } from "../../helper/MinIndex";
import { fnMaxIndex } from "../../helper/MaxIndex";
//import { fnFocus, fnGetElement } from "functions/Focus";

// const currentHotKeys = [
//     {
//         idKey: "ArrowUp"
//     },
//     {
//         idKey: "ArrowDown"
//     },
//     {
//         idKey: "Enter"
//     },
// ]

const TaMenuItem = memo(({
    id, menu, onClick,
    subMenu, onClickSubMenu, disabled,
    hotkey, invisibleHotkey, onClickConfig,
}) => {
    const classes = ListStyle({ pointer: true, hover: true });
    const subMenuClasses = ListStyle({ paddingLeft: "23px" });
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentExpandId, setCurrentExpandId] = useState({});
    const [selectedIndex, setSelectedIndex] = useState(-1);
    const [selectedSubIndex, setSelectedSubIndex] = useState(-1);
    const [selectedSubId, setSelectedSubId] = useState();
    const [lastIndex, setLastIndex] = useState(-1);
    const [focusMenu, setFocusMenu] = useState(true);
    // const [currentHk, setCurrentHk] = useState(currentHotKeys)

    const fnOnClickMenu = useCallback((elementId, uniqueKey, event) => {
        if (invisibleHotkey) {
            const currentTarget = event === undefined ? document.getElementById(id) : event.currentTarget;
            return setAnchorEl(currentTarget)
        }
        return onClickConfig();
    })

    const fnOnClose = useCallback(() => setAnchorEl(null))
    const fnOnArrowUp = () => {
        if (focusMenu) {
            return setSelectedIndex(fnMinIndex(0, selectedIndex));
        }
        //return setSelectedSubIndex(fnMinIndex(0, selectedSubIndex));
    };
    const fnOnArrowDown = () => {
        if (focusMenu) {
            return setSelectedIndex(fnMaxIndex(menu.length - 1, selectedIndex));
        }
        // const newIndex = fnMaxIndex(subMenu[selectedSubId].length - 1, selectedSubIndex);
        // if (selectedSubIndex === newIndex) {
        //     try {
        //         //console.log(lastIndex, menu[lastIndex + 1].id);
        //         fnFocus(`listMenuItem${menu[lastIndex + 1].id}`);
        //         setLastIndex(-1);
        //         setSelectedSubIndex(-1);
        //     } catch (error) {

        //     }
        // }
        // return setSelectedSubIndex(fnMaxIndex(subMenu[selectedSubId].length - 1, selectedSubIndex));

    }

    // const fnOnEnter = () => {
    //     if (anchorEl !== null) {
    //         try {
    //             const currentMenu = menu[selectedIndex]
    //             const currentElement = fnGetElement(`listMenuItem${currentMenu.id}`)
    //             currentElement.click();
    //         } catch (error) {
    //             console.error('error clicking list => ', error)
    //         }

    //     }
    // }

    // const fnOnHotkey = (e, option, origin) => {
    //     if (anchorEl !== null) {
    //         switch (e.key) {
    //             case "ArrowUp":
    //                 return fnOnArrowUp();
    //             case "ArrowDown":
    //                 return fnOnArrowDown();
    //             case "Enter":
    //                 return fnOnEnter();
    //             default:
    //         }
    //     } else {
    //         switch (e.key) {
    //             case hotkey:
    //                 return fnOnClickMenu();
    //             default:
    //         }
    //     }

    // }

    // useSpKeys(["body", "button", "div"], currentHk, fnOnHotkey);

    useEffect(() => {
        if (anchorEl === null) {
            setCurrentExpandId({});
            setSelectedIndex(-1);
        } else {
            setSelectedIndex(0)
        }
    }, [anchorEl])

    // useEffect(() => {
    //     const currentMenu = menu[selectedIndex]
    //     if (currentMenu) {
    //         fnFocus(`listMenuItem${currentMenu.id}`)
    //     }
    // }, [selectedIndex])

    // useEffect(() => {
    //     if (hotkey !== "") {
    //         setCurrentHk(preValue => preValue.concat({ idKey: hotkey }))
    //     } else {
    //         setCurrentHk(currentHotKeys);
    //     }
    // }, [hotkey])

    return (
        <React.Fragment>
            <TaFab
                id={id}
                icon="menu"
                color="orange"
                onClick={fnOnClickMenu}
                //hotkey={hotkey}
                aria-controls={`list${id} `}
                aria-haspopup="true"
                disabled={disabled}
            />
            <Popover
                id={`listMenu${id} `}
                open={Boolean(anchorEl)}
                anchorEl={anchorEl}
                onClose={fnOnClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}>
                <List component="nav" classes={{ root: classes.rootList }}>
                    {
                        menu.map((x, i) => {
                            return (
                                // Agregada el prop key para evitar errores
                                <React.Fragment key={`element${uuid()}`}>
                                    <ListItem
                                        id={`listMenuItem${x.id}`}
                                        classes={{ root: classes.root, button: classes.button }}
                                        key={uuid()}
                                        onClick={() => {
                                            setCurrentExpandId(prev => ({ ...prev, [x.id]: !(prev[x.id]) ? true : !prev[x.id] }))
                                            if (x.expand) {
                                                // if (!currentExpandId[x.id]) {
                                                //     setLastIndex(selectedIndex)
                                                //     setSelectedIndex(-1);
                                                //     setSelectedSubIndex(0);
                                                //     setSelectedSubId(x.id)
                                                //     fnFocus(`subListItem${subMenu[x.id][0].id}`);
                                                //     setFocusMenu(false);
                                                // }
                                                return
                                            }
                                            fnOnClose()
                                            onClick(x.id)
                                        }}>
                                        {x.name}
                                        {(!x.expand) ? '' : !(currentExpandId[x.id]) ? <ExpandMore /> : currentExpandId[x.id] ? <ExpandLess /> : <ExpandMore />}
                                    </ListItem>
                                    <Collapse in={currentExpandId[x.id]} timeout="auto">
                                        <List component="nav" classes={{ root: classes.rootList }}>
                                            {(subMenu) && (subMenu[x.id]) && subMenu[x.id].map((submenu, submenui) => (
                                                <ListItem
                                                    id={`subListMenuItem${submenu.id}`}
                                                    classes={{ root: subMenuClasses.root, selected: classes.selected, button: classes.button }}
                                                    key={uuid()}
                                                    selected={selectedSubIndex === submenui}
                                                    onClick={() => {
                                                        fnOnClose()
                                                        onClickSubMenu(submenu.id, x.id)
                                                    }}>
                                                    {submenu.name}
                                                </ListItem>
                                            ))}
                                        </List>

                                    </Collapse>
                                </React.Fragment>
                            )
                        })
                    }
                </List>
            </Popover>
        </React.Fragment>
    );
});

TaMenuItem.propTypes = {
    menu: PropTypes.array,
    onClick: PropTypes.func,
    subMenu: PropTypes.array,
    onClickSubMenu: PropTypes.func,
    disabled: PropTypes.bool,
    hotkey: PropTypes.string,
    invisibleHotkey: PropTypes.bool,
    onClickConfig: PropTypes.func,
};

TaMenuItem.defaultProps = {
    menu: [],
    onClick: () => { },
    subMenu: [],
    onClickSubMenu: () => { },
    disabled: false,
    hotkey: "",
    invisibleHotkey: true,
    onClickConfig: () => { },
}

export default TaMenuItem;