import React, { memo, useEffect, useContext } from 'react';

import './fonts/Archivo-Regular.ttf';
import './index.css';

import { LoginProvider } from "./Login/LoginContext";
import { SnackbarProvider } from "./components/snackbar/SnackbarContext";
import Main from "./Main";
import Snackbar from "./components/snackbar/Snackbar";
import { AuthorizeProvider } from "./components/authorize/AuthorizeContext";
import Authorize from "./components/authorize/Authorize";
import { SWContext } from "./components/ServiceWorker/SWContext";

import {
    BrowserRouter as Router,
    Switch,
    Route,
} from "react-router-dom";
import { QueryParamProvider } from 'use-query-params';

const App = memo(({ serviceWorker }) => {
    const { swDispatch } = useContext(SWContext);
    useEffect(() => {
        const appUpdate = localStorage.getItem('appUpdate') === null ? false : localStorage.getItem('appUpdate');
        swDispatch.hasUpdate(appUpdate);

        if (process.env.NODE_ENV === 'development') {
            return serviceWorker.unregister();
        }
        serviceWorker.register({
            onSuccess: () => {
                localStorage.setItem('appUpdate', false);
            },
            onUpdate: reg => {
                localStorage.setItem('appUpdate', true);
                swDispatch.hasUpdate(true);
            },
        })
    }, []);
    return (
        <AuthorizeProvider>
            <SnackbarProvider>
                <LoginProvider>
                    <Router>
                        <QueryParamProvider ReactRouterRoute={Route}>
                            <Switch>
                                <Route exact path="/"><Main /></Route>
                            </Switch>
                        </QueryParamProvider>
                    </Router>
                    <Snackbar />
                    <Authorize />
                </LoginProvider>
            </SnackbarProvider>
        </AuthorizeProvider>
    );
});

export default App;