export const AUTHORIZE_INITIALSTATE = {
    isOpen: false,
    authType: 0,
    title: '',
    data: undefined,
    onAccept: undefined,
    onDeny: undefined,
    user: "",
    pass: "",
    pin: "",
    isLoading: false,
}
export const AUTHORIZE_SETVALUE = "AUTHORIZE_SETVALUE"
export const AUTHORIZE_OPEN = "AUTHORIZE_OPEN"
export const AUTHORIZE_CLOSE = "AUTHORIZE_CLOSE"