import { Grid, Paper, TextField } from '@material-ui/core'
import React, { memo, useContext, useState } from 'react'
import { useForm } from 'react-hook-form'
import TaButton from '../components/button/TaButton'
import Loader from '../components/loader/Loader'
import { SnackbarContext } from '../components/snackbar/SnackbarContext'
import TaGeneralTitle from '../components/typography/TaGeneralTitle'
import { regexEmail } from '../helper/RegularExpressions'
import { isOK } from '../helper/RestStatus'
import useTextField from '../helper/UseTextField'
import { postValidateEmail } from './newPasswordApi'

const NewPasswordRequest = memo(({ onReturn }) => {
  const { handleSubmit, register, errors, reset } = useForm()
  const email = useTextField('email', 'Correo', errors, "text")
  const { sbDispatch } = useContext(SnackbarContext)
  const [loader, setloader] = useState(false)

  const fnOnSubmit = async (data) => {
    try {
      setloader(true)
      const response = await postValidateEmail({ ...data })
      setloader(false)
      if (isOK(response.status)) {
        if (response.data.errorCode === 0) {
          sbDispatch.success(response.data.message)
          return reset()
        }
      }
      return sbDispatch.error(response.data.message)
    } catch (error) {
      setloader(false)
      console.error(`fnOnSubmit => ${error}`)
    }
  }

  return (
    <Paper className={'center'} elevation={1} style={{ padding: '5px', width: '45%' }}>
      <form onSubmit={handleSubmit(fnOnSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <TaGeneralTitle title={'Solicitud de cambio de contraseña'} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
            <Paper elevation={0} style={{ padding: '15px' }}>
              <TextField {...email} fullWidth disabled={loader} inputRef={register({ required: "Requerido", pattern: regexEmail, })} />
            </Paper>
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'}>
            <TaButton
              id={'btnReturn'}
              backgroundColor={'var(--main-bg-color5)'}
              label={'Regresar'}
              onClick={onReturn}
              disabled={loader} />
            <TaButton
              id={'btnReturn'}
              backgroundColor={'var(--main-bg-color4)'}
              label={'Aceptar'}
              type={'submit'}
              disabled={loader} />
          </Grid>
          <Loader isLoading={loader} />
        </Grid>
      </form>
    </Paper>
  )
})

export default NewPasswordRequest
