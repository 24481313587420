import { Grid, List, ListItem, Paper } from '@material-ui/core'
import React, { memo } from 'react'
import ListStyle from '../styles/List'
import TaTypography from "../components/typography/TaTypography";

const AdvancePaymentList = memo(({ data, advanceTotal, heightList }) => {
  const classes = ListStyle({ color: 'var(--main-text-color0)' })
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper elevation={0} id={'listPaper'} style={{ height: heightList, overflowX: 'hidden', overflowY: 'hidden !important' }}>
          <List component={'nav'}>
            {data.map(x => {
              return (
                <ListItem key={x.uniqueKey}
                  className={classes.root}>
                  <Grid container spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TaTypography fontSize="11pt">{`Fecha de registro: ${x.dateReg}`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TaTypography fontSize="11pt">{`Monto original: ${x.transAmount}`}</TaTypography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                      <TaTypography fontSize="14pt" align='right'>{`Saldo restante: ${x.remainingAmount}`}</TaTypography>
                    </Grid>
                  </Grid>
                </ListItem>
              )
            })}
          </List>
        </Paper>
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align={'right'}>
        <TaTypography fontSize="16pt" align='right'>{`Saldo total: ${advanceTotal}`}</TaTypography>
      </Grid>
    </Grid>
  )
})

export default AdvancePaymentList