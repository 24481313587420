import { fnPostUrl } from "../axios/Api";

export const postRegister = (data) => {

    const body = {
        "idUser": 0,
        "idClient": data.idClient,
        "name": data.name,
        "address": data.address,
        "email": data.email,
        "typeUser": 1,
        "password": data.pass,
        "phone": data.phone,
        //AGREGADO CAMPO FAMILIA
        "family": data.family,
        "active": -1,
    }

    const apiRequest = {
        endpoint: "/Wstng/User",
        body: body,
    }

    return fnPostUrl(apiRequest)
}