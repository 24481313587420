import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useForm } from "react-hook-form"

import {
    Grid,
    TextField,
} from "@material-ui/core";

import TaFab from "../components/fab/TaFab";
import TaButton from "../components/button/TaButton";

import {
    regexDate,
} from "../helper/RegularExpressions"

import {
    textfieldStyles,
    textfieldLabelStyles,
} from "../styles/TextField";

const ConciliationFilter = memo(({ onSearch, disabled }) => {

    const { handleSubmit, register, errors, setValue } = useForm();
    const textfieldClasses = textfieldStyles();
    const textfieldLabelClasses = textfieldLabelStyles();

    const fnOnSubmit = async (data) => onSearch(data)

    return (
        <form onSubmit={handleSubmit(fnOnSubmit)}>
            <Grid container spacing={1}>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} style={{ minHeight: "65px" }}>
                    <TextField
                        name="iniDate"
                        label="Desde"
                        fullWidth
                        inputRef={register({
                            required: "Requerido",
                            pattern: regexDate,
                        })}
                        helperText={errors.iniDate && errors.iniDate.message}
                        error={(errors.iniDate)}
                        InputProps={{
                            classes: textfieldClasses,
                        }}
                        InputLabelProps={{
                            classes: textfieldLabelClasses,
                            shrink: true,
                        }}
                        type="date" 
                        disabled={disabled} />
                </Grid>
                <Grid item xs={12} sm={3} md={3} lg={3} xl={3} style={{ minHeight: "65px" }}>
                    <TextField
                        name="endDate"
                        label="Hasta"
                        fullWidth
                        inputRef={register({
                            required: "Requerido",
                            pattern: regexDate,
                        })}
                        helperText={errors.endDate && errors.endDate.message}
                        error={(errors.endDate)}
                        InputProps={{
                            classes: textfieldClasses,
                        }}
                        InputLabelProps={{
                            classes: textfieldLabelClasses,
                            shrink: true,
                        }}
                        type="date" 
                        disabled={disabled} />
                </Grid>
                <Grid item xs={12} sm={2} md={2} lg={2} xl={2} style={{ minHeight: "65px" }}>
                    <TaFab
                        id="btnSearchCxC"
                        icon="search"
                        color="blue"
                        type="submit"
                        disabled={disabled} />
                </Grid>
            </Grid>
        </form>
    );
});

ConciliationFilter.propTypes = {
    onSearch: PropTypes.func,
};

export default ConciliationFilter;