export const CXC_INITIALSTATE = {
  paymentMethods: [],
  cxc: [],
  selectedPaymentMethods: [],
  advancePaymentList: [],
  advanceTotal: 0,
  exchangeFactor: 1,
  totalBalance: 0,
  totalPaidOut: 0,
  totalDebt: 0,
  totalDeferred: 0,
  defaultCurrency: 1,
  exchangeLabel: "$",
  totalBalanceLabel: 0,
  totalPaidOutLabel: 0,
  totalDeferredLabel: 0,
  modal: {
    isOpen: false,
  }
}

export const CXC_SETPAYMENTMETHODS = 'CXC_SETPAYMENTMETHODS';
export const CXC_SET = 'CXC_SET';
export const CXC_ONCHANGETABLE = 'CXC_ONCHANGETABLE';
export const CXC_SELECTEDPAYMENTMETHOD = 'CXC_SELECTEDPAYMENTMETHOD';
export const CXC_ONCHANGEPAYMENT = 'CXC_ONCHANGEPAYMENT';
export const CXC_RESETUI = 'CXC_RESETUI'
export const CXC_SETFACTOR = 'CXC_SETFACTOR'
export const CXC_CHANGECURRENCY = 'CXC_CHANGECURRENCY'
export const CXC_DELSELECTEDPAYMENTMETHOD = 'CXC_DELSELECTEDPAYMENTMETHOD'
export const CXC_ONSUBMITPAYMENT = 'CXC_ONSUBMITPAYMENT'
export const CXC_MODALSETVALUE = 'CXC_MODALSETVALUE'
export const CXC_SETADVANCELIST = 'CXC_SETADVANCELIST'