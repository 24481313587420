import React, { memo, useContext } from 'react';
import PropTypes from 'prop-types';

import {
    Grid,
    Paper,
} from "@material-ui/core";

import TaFab from "../components/fab/TaFab";
import TaTypography from "../components/typography/TaTypography";

import {
    LoginContext,
} from "../Login/LoginContext";
import TaMenuItem from "../components/menuitem/TaMenuItem"; 
import logoImg from "../img/logo.png";

import uuid from 'react-uuid'

//MODIFICADO POR Reinaldo Zambrano
// AGREGUE EL TERCER OBJETO PARA PODER ACCEDER AL MODULO DE BANCOS
// EDITE LOS ID, YA NO VAN DESDE CERO HASTA DOS, DEBIDO A QUE NO PERMITIAN EL CAMBIO DESDE BANCOS A OTROS MODULOS
const menuList = [
    {
        id: 1,
        name: "Conciliación",
        uniqueKey:uuid()
    },
    {
        id: 2,
        name: "Usuarios",
        uniqueKey:uuid()
    },
    {
        id: 3,
        name: "Bancos",
        uniqueKey:uuid()
    }
]

const HeaderBar = memo(({ userName, onClickMenu, typeUser }) => {
    const { setLogState } = useContext(LoginContext)
    const fnLogout = () => setLogState.setLogoff();

    return (
        <Paper style={{ padding: "5px" }}>
            <Grid container alignContent="flex-start" alignItems="center" spacing={1}>
                <Grid item xs={3} sm={2} md={1} lg={1} xl={1} hidden={typeUser === 1}>
                    <TaMenuItem
                        id="btnMenuAdmin"
                        menu={menuList}
                        hidden={typeUser === 1}
                        onClick={onClickMenu}/>
                </Grid>
                <Grid item xs={3} sm={2} md={1} lg={1} xl={1} hidden={!(typeUser === 1)}>
                </Grid>
                <Grid item xs={4} sm={4} md={3} lg={3} xl={3}>
                    <TaTypography fontSize="14pt" color="var(--main-text-color2)">{userName}</TaTypography>
                </Grid>
                <Grid item xs={6} sm={4} md={4} lg={4} xl={4}>
                    <TaTypography align="center" fontSize="20pt" color="var(--main-text-color2)">{'ACADEMIA WASHINGTON'}</TaTypography>
                </Grid>
                <Grid item xs={3} sm={2} md={4} lg={4} xl={4} align="right">
                    <TaFab
                        id="btnLogout"
                        icon="exit"
                        color="red"
                        onClick={fnLogout}
                        tooltip={"Cerrar sesión"} />
                </Grid>
            </Grid>
        </Paper>
    );
});

HeaderBar.propTypes = {
    userName: PropTypes.string,
    onClickMenu: PropTypes.func,
    typeUser: PropTypes.number,
};

export default HeaderBar;