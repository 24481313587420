import React, { useState, useCallback, useContext } from 'react';

import Grid from "@material-ui/core/Grid";

import { AuthorizeContext } from './AuthorizeContext';
import {
    close,
    setValue,
} from "./AuthorizeActions"
import TaTypography from '../typography/TaTypography';
import TaFab from "../fab/TaFab";
import TaTextField from "../textfield/TaTextFieldV2"

import {
    postAuthRest,
} from "./api/ApiAuthorize"

import {
    SnackbarContext,
} from "../snackbar/SnackbarContext";

import TaDialog from "../dialog/TaDialog"

const Authorize = () => {
    const [loader, setLoader] = useState(false);
    const { sbDispatch } = useContext(SnackbarContext);
    const { authState, authDispatch } = useContext(AuthorizeContext)
    const { title, isOpen, data, user, pass, pin, authType, onAccept, onDeny } = authState

    const fnOnClose = useCallback((id) => {
        authDispatch(close())
        if (id === 'btnCloseAuthorize') {
            if (onDeny) {
                onDeny(data);
            }
        } else {
            if (onAccept) {
                onAccept(data)
            }
        }

    }, [onDeny, data]);
    const fnOnSetValue = useCallback((name, value) => authDispatch(setValue(name, value)), [])

    const fnOnCheck = useCallback(async () => {

        let request = {
            ...data,
        }, response;

        if (authType === 0) {
            return fnOnClose();
        } else if (authType === 1) {
            request = {
                ...request,
                pin: pin
            }
            try {
                response = await postAuthRest(request);
                if (response.status === 200) {
                    return fnOnClose();
                }

                sbDispatch.api(response.status, response.data)
            } catch (error) {
                console.error('fnOnCheck error => ', error)
            }

        }
    }, [data, authType, user, pass, pin, onAccept])

    return (
        <TaDialog
            isOpen={isOpen}
            onClose={fnOnClose}
            maxWidth="xs"
            title={title}
            content={
                <Grid container alignContent={"flex-start"} alignItems={"center"} spacing={1}>
                    {
                        (data) && <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                            {authType === 1 && <TaTextField
                                id="txtRestUserPin"
                                name="pin"
                                label="PIN"
                                style={{ width: "175px" }}
                                value={pin}
                                onBlur={fnOnSetValue}
                                maxLength="4"
                                placeholder="Código de 4 dígitos"
                                type="password"
                                autoFocus />}
                        </Grid>
                    }
                </Grid>
            }
            actions={
                <Grid container alignContent="flex-start" alignItems="flex-start">
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align='right'>
                        <TaFab
                            id="btnCloseAuthorize"
                            icon="clear"
                            color="red"
                            onClick={fnOnClose} />
                        <TaFab
                            id="btnCheckAuthorize"
                            icon="total"
                            color="green"
                            onClick={fnOnCheck}
                            hidden={!(onAccept)} />
                    </Grid>
                </Grid>
            }
            loading={loader}
        />
    );
};
export default Authorize;