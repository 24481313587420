import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogContent,
    DialogActions,
    DialogTitle,
} from "@material-ui/core/"

import {
    makeStyles,
} from '@material-ui/core/styles'

import TaTypography from "../typography/TaTypography";

const DialogStyles = makeStyles({
    title: {
        margin: "5px",
        padding: "10px !important",
        backgroundColor: "var(--main-bg-color1)"
    },
    content: {
        margin: "5px",
        padding: "10px !important",
    },
    actions: {
        margin: "5px",
        padding: "10px !important",
    },
})

const TaDialog = memo(({ isOpen, onClose, fullWidth, maxWidth, title, content, actions }) => {
    const classes = DialogStyles();
    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            fullWidth={fullWidth && fullWidth}
            maxWidth={maxWidth}>
            {title && <DialogTitle className={classes.title}>{<TaTypography text={title} isTitle />}</DialogTitle>}
            {content && <DialogContent className={classes.content}>{content}</DialogContent>}
            {actions && <DialogActions className={classes.actions}>{actions}</DialogActions>}
        </Dialog>
    )
})

TaDialog.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    fullWidth: PropTypes.bool,
    maxWidth: PropTypes.string,
    title: PropTypes.any,
    content: PropTypes.any,
    actions: PropTypes.any,
};

TaDialog.defaultProps = {
    isOpen: false,
    fullWidth: true,
    maxWidth: "sm",
};

export default TaDialog