import React, { memo, useState, useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import uuid from 'react-uuid';

import { makeStyles } from '@material-ui/core/styles';

import {
    Tabs,
    Tab,
} from '@material-ui/core/';


const useTabsStyles = makeStyles(({ breakpoints }) => ({
    root: props => ({
        backgroundColor: props.customBackGround ? props.customBackGround : 'var(--main-bg-color1)',
        //width: props.width ? props.width : '100%',
        [breakpoints.down('md')]: {
            overflowX: 'auto'
        },
    }),
    scroller: props => ({
        overflow: 'visible!important',
    }),
    indicator: {
        display: 'none',
    },
}));

const TabStyles = makeStyles(({ spacing }) => {
    return {
        root: props => ({
            opacity: 1,
            overflow: 'initial',
            minHeight: spacing(7),
            color: props.customUnactiveColor ? props.customUnactiveColor : "var(--main-bg-color0)",
            background: props.customBackGround ? props.customBackGround : 'rbga(0,0,0,0)',
            transition: '0.1s',
            fontFamily: "Archivo",
            fontSize: "14pt",
            flexGrow: 1,
            padding: "2px",
            '&:before': {
                content: '" "',
                position: 'absolute',
                bottom: 0,
                right: 0,
                left: 0,
                paddingTop:'5px',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                height: '80%',
                boxShadow: '3px 3px 8px 0 rgba(0,0,0,0.38) !important',
                backgroundColor: props.customUnactiveBackground ? props.customUnactiveBackground : 'var(--main-bg-color8)',
            },
            '&:after': {
                pointerEvents: 'none',
                transition: '0.2s',
                content: '" "',
                position: 'absolute',
                bottom: 0,
                right: 0,
                transform: 'translateX(100%)',
                paddingTop:'5px',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '-5px',
                boxShadow: '3px 3px 8px 0 rgba(0,0,0,0.38) !important',
                height: '80%',
                display: 'block',
                zIndex: 2,
            },
        }),
        selected: props => ({
            color: props.customSelectedColor ? props.customSelectedColor : 'var(--main-bg-color8) !important',
            zIndex: 3,
            borderTopLeftRadius: '5px',
            borderTopRightRadius: '5px',
            borderBottomRightRadius: '-5px',
            '&:before': {
                backgroundColor: props.customSelectedBackGround ? props.customSelectedBackGround : '#fff !important',
                boxShadow: '3px 3px 8px 0 rgba(0,0,0,0.38) !important',
                height: '87% !important',
                borderTopLeftRadius: '5px',
                borderTopRightRadius: '5px',
                borderBottomRightRadius: '-5px',
            },
            '&:after': {
                height: '87% !important',
            },
        }),
        wrapper: {
            paddingTop: "10px",
            zIndex: 2,
            textTransform: 'initial',
        },
    }
})

const TaTabs = memo(({ tabIndex, labelArray, bodyArray, onChange, customBackGround, width, customSelectedBackGround, customSelectedColor, customUnactiveBackground, customUnactiveColor }) => {
    const tabClasses = TabStyles({ customBackGround, width, customSelectedBackGround, customSelectedColor, customUnactiveBackground, customUnactiveColor, labelArray })
    const tabsClasses = useTabsStyles({ customBackGround, width })
    const currentLabel = useMemo(() => {
        const newLabelArray = labelArray.map(x => {
            return {
                id: uuid(),
                label: (x.label) && x.label,
                icon: (x.icon) && x.icon,
                hidden: (x.hidden) && x.hidden
            }
        })
        return newLabelArray.filter(x => (x.hidden === false || x.hidden === undefined)).map(x => {
            return <Tab classes={tabClasses} key={x.id} label={x.label} icon={x.icon} ></Tab>
        })
    }, [labelArray, tabClasses])

    const currentBody = useMemo(() => {
        return bodyArray.filter((x,i) => i === tabIndex)
    }, [bodyArray, tabIndex])

    const fnOnChange = useCallback((event, value) => (onChange) && onChange(value))
    return (
        <React.Fragment>
            <Tabs
                value={tabIndex}
                classes={tabsClasses}
                onChange={fnOnChange}
                variant='fullWidth'>
                {currentLabel}
            </Tabs>
            {currentBody}
        </React.Fragment>
    );
});

TaTabs.propTypes = {
    tabIndex: PropTypes.number,
    labelArray: PropTypes.array,
    bodyArray: PropTypes.array,
    onChange: PropTypes.func,
};

export default TaTabs;