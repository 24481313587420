import React, { memo, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import {
    textfieldStyles,
    textfieldLabelStyles,
} from "../styles/TextField";

const useTextField = (name, label, errors, type, maxLength, onKeyDown) => {
    const textfieldClasses = textfieldStyles();
    const textfieldLabelClasses = textfieldLabelStyles();
    const [textfield, setTextfield] = useState({
        name: name,
            label: label,
            InputProps: {
                classes: textfieldClasses,
            },
            InputLabelProps: {
                classes: textfieldLabelClasses,
                shrink: true,
            },
            inputProps:{ maxLength: (maxLength) && maxLength},
            type: type,
            onKeyDown: (onKeyDown) && onKeyDown,
            autoComplete: "new-password"
    });

    useEffect(() => {
        setTextfield({
            ...textfield,
            helperText: errors ? errors.hasOwnProperty(name) ? errors[name].message : '' : '',
            error: errors && errors.hasOwnProperty(name),
        })
    }, [errors]);
    return textfield;
}

export default useTextField;