import React, { memo, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Grid,
  Paper,
  TextField,
  List,
  ListItem,
} from "@material-ui/core";

import useTextField from "../helper/UseTextField";
import TaGeneralTitle from "../components/typography/TaGeneralTitle";
import ListStyle from "../styles/List";
import TaTypography from "../components/typography/TaTypography"
import { useForm } from "react-hook-form";

const UserInformation = memo(({ selectedUser, onClick, setSelectedUser, disabled, height}) => {
  const { register, errors, watch, setValue } = useForm();
  const txtIdClient = useTextField('idClient', 'Cédula/RIF');
  const txtName = useTextField('name', 'Representante/Empresa');
  const txtEmail = useTextField('email', 'Correo electrónico');
  const txtPhone = useTextField('phone', 'Teléfono', errors);
  const txtFamily = useTextField('family', 'Familia(s)', errors);
  const txtAddress = useTextField('address', 'Dirección', errors);
  const classes = ListStyle({ hover: true, pointer: true, });
  //MODIFICADO POR Reinaldo Zambrano
  //Añadida Logica de modificación de data de selectedUser desde el formulario especificamente
  //para modificar Dirección y telefono
  const newAdrress = watch('address')
  const newPhone = watch('phone')
  useEffect(() => {
    if (!disabled) {
      setValue('address', selectedUser.address);
      return setValue('phone', selectedUser.phone);
    }
    setValue('address', '');
    return setValue('phone', '');
  }, [disabled])

  // MODIFICADO POR Reinaldo Zambrano
  // Cambie la logica con la que vigilo los campos, colocando un useEffect por cada campo, debido a una incosistencia en la ejecucion
  // cuando se agrupa la logica en un solo bloque EJEMPLO EN LA SIGUIENTE LINEA
  // useEffect(() => {
    //   if (newAdrress) {
  //     setSelectedUser({ ...selectedUser, address: newAdrress })
  //   } else if (newPhone) {
  //     setSelectedUser({ ...selectedUser, phone: newPhone })
  //   }
  // }, [newAdrress, newPhone])
  useEffect(() => { if (newAdrress) setSelectedUser({ ...selectedUser, address: newAdrress }) }, [newAdrress])
  useEffect(() => { if (newPhone) { setSelectedUser({ ...selectedUser, phone: newPhone }) } }, [newPhone])

  return (
    <form>
      <Grid container alignContent="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TaGeneralTitle title={`Información del usuario`} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField {...txtEmail} fullWidth value={selectedUser.email} disabled />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField {...txtIdClient} fullWidth value={selectedUser.idClient} disabled />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField {...txtName} fullWidth value={selectedUser.name} disabled />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          < TextField {...txtAddress} fullWidth disabled={disabled} inputRef={register({ required: 'Requerido' })} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField {...txtPhone} fullWidth disabled={disabled} inputRef={register({ required: 'Requerido' })} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TextField {...txtFamily} fullWidth value={selectedUser.family} disabled />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <TaTypography>{`Cliente en SAINT: `}</TaTypography>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper style={{ padding: "5px", height: height, overflow: 'auto' }} elevation={0}>
            <List
              component="nav">
              {selectedUser.client && selectedUser.client.map(x => {
                return (
                  <ListItem
                    key={x.uniqueKey}
                    classes={{ root: classes.root }}
                    onClick={() => onClick(x)}>
                    <Grid container style={{ margin: "5px" }} alignItems="center" alignContent={"flex-start"} spacing={1}>
                      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>{x.name}</Grid>
                    </Grid>
                  </ListItem>
                )
              })}
            </List>
          </Paper>
        </Grid>
      </Grid>
    </form>
  );
});

UserInformation.propTypes = {
  idClient: PropTypes.string,
  name: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  address: PropTypes.string,
  client: PropTypes.array,
};

UserInformation.defaultProps = {
  client: [],
}

export default UserInformation;