import React, { memo } from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    List,
    ListItem,
    Paper,
} from "@material-ui/core/"
import TaTypography from "../components/typography/TaTypography";
import TaFab from "../components/fab/TaFab";
import ListStyle from "../styles/List";
import moment from "moment"

const SelectedPaymentMethods = memo(({ data, onClick, height, isLoading }) => {

    const classes = ListStyle({ color: "var(--main-text-color1)", backgroundColor: "var(--main-bg-color1)" });

    return (
        //AGREGADO OVERFLOW PARA EVITAR EL SOLAPAMIENTO DE LA LISTA CON EL PANEL DE TOTALIZACIÓN
        <Paper elevation={0} style={{ height: `${height}px`, overflow: 'auto' }}>
            <Grid container alignContent="flex-start" alignItems="flex-start">
                <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <List
                        component="nav">
                        {data && data.map(x => {
                            return (
                                <ListItem
                                    key={x.uniqueKey}
                                    className={classes.root}>
                                    <Grid container style={{ margin: "5px" }} alignItems="center" alignContent={"flex-start"} spacing={1}>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                                            {x.name}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            {`Nro Tran: ${x.refere}`}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            {`Monto: ${x.amount.formatNumb(2)}`}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
                                            {/* SOLUCIONADO BUG DE RESTA DE UN DIA A LA FECHA */}
                                            {`Fecha: ${moment(x.date).format("DD/MM/YYYY")}`}
                                            {/* {`Fecha: ${moment(new Date(x.date)).format("DD/MM/YYYY")}`} */}
                                        </Grid>
                                        <Grid item xs={6} sm={6} md={6} lg={6} xl={6} hidden={x.currentCurrency === 1}>
                                            {/* Agregado valor condicional dependiendo del tipo de moneda */}
                                            {x.currentCurrency === 1 ? '' : `Factor: ${x.factor}`}
                                            {/* {x.currentCurrency === 1 ? '' : `Factor: ${x.factor.formatNumb(2)}`} */}
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="center">
                                            <TaFab
                                                icon="clear"
                                                color="red"
                                                onClick={onClick}
                                                //AGREGADO DISABLED
                                                disabled={isLoading}
                                                uniqueKey={x.uniqueKey} />
                                        </Grid>
                                    </Grid>
                                </ListItem>
                            )
                        })}
                    </List>
                </Grid>
            </Grid>
        </Paper>
    );
});

SelectedPaymentMethods.propTypes = {
    data: PropTypes.array.isRequired,
};

export default SelectedPaymentMethods;