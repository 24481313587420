import React, { memo, useState, useContext, useEffect } from 'react'
import Login from "../Login/"
import Cxc from "../CxC/"
import HeaderBar from "../HeaderBar/"
import Conciliation from "../Conciliation"
import User from "../User"

import {
  LoginContext,
} from "../Login/LoginContext"

import {
  Grid,
} from "@material-ui/core";

import Register from "../Register";
import Bank from '../Bank/Bank';
import { useQueryParam, StringParam } from 'use-query-params';

import { getToken } from "./ApiMain";
import NewPasswordRequest from '../NewPassword/NewPasswordRequest'
import NewPasswordForm from '../NewPassword/NewPasswordForm'

const defaultUser = {
  email: '',
  id: -100,
  name: ''
}

const Main = memo(({ }) => {
  const [token] = useQueryParam('token', StringParam);
  const { loginState } = useContext(LoginContext)
  const [menuIndex, setMenuIndex] = useState(1);
  const [isRegister, setIsRegister] = useState(false)
  const [requestNewPassw, setRequestNewPassw] = useState(false)
  const [isNewPassword, setIsNewPassword] = useState(false)
  const [currentUser, setCurrentUser] = useState(defaultUser)
  const [loader, setLoader] = useState(false)
  const fnOnClickMenu = (id) => setMenuIndex(id)

  const fnOnClickRegister = () => setIsRegister(val => !val);

  const fnOnRequestNewPassw = () => {
    setIsRegister(false);
    setRequestNewPassw(true)
  }

  const fnReset = () => {
    setIsRegister(false);
    setRequestNewPassw(false)
    setIsNewPassword(false)
  }

  //MODIFIQUE LA FUNCION DE VALIDATETOKEN DEBIDO A QUE NO OBTENGO UN PARAMETRO TYPE 
  //ASI QUE SI ESTA VALIDO LO PASO DIRECTO, SI NO ES VALIDO ENTONCES NO PASA NADA
  const validateToken = async (token) => {
    setLoader(true)
    const response = await getToken(token)
    setLoader(false)
    if (response.status === 200) {
      if (response.data.errorCode === 0) {
        if (response.data.message === 'Token valido') {
          setCurrentUser({ ...response.data.data })
          setIsNewPassword(true)
        }
      }
    }
    return;
  }

  // const validateToken = async (token) => {
  //     const response = await getToken(token)
  //     if (response.status === 200) {
  //         if (response.data.errorCode === 0) {
  //             const { type } = response.data.data
  //             switch (String(type).toLowerCase()) {
  //                 default:
  //                     return;
  //             }
  //         }
  //     }
  //     return;
  // }

  useEffect(() => {
    // console.log('token => ', token)
    if (token) {
      validateToken(token);
    }
  }, [token]);

  return (
    <React.Fragment>
      {!(loginState.isLogin)
        //AGREGADA VALIDACION DE TOKEN VIA QUESRYSTRING PARA ABRIR FORMULARIO DE CAMBIO DE CONTRASEÑA
        ? isNewPassword
          // AGREGADO FORMULARIO DE CAMBIO DE CONTRASEÑA
          ? <NewPasswordForm onReturn={fnReset} userId={currentUser.id} />
          : isRegister
            ? <Register onReturnLogin={fnOnClickRegister} />
            //AGREGADA VALIDACION DE SOLICITUD DE NUEVA CONTRASEÑA
            : requestNewPassw
              //AGREADO FORMULARIO PARA SOLICITUD DE NUEVA CONTRASEÑA
              ? <NewPasswordRequest onReturn={fnReset} />
              : <Login onGoRegister={fnOnClickRegister} onRequestNewPassw={fnOnRequestNewPassw} isLoading={loader} />
        : <Grid container alignContent="flex-start" alignItems="center" spacing={2}>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            <HeaderBar userName={loginState.name} onClickMenu={fnOnClickMenu} typeUser={loginState.typeUser} />
          </Grid>
          <Grid item xs={12} sm={12} md={12} xl={12} lg={12}>
            {loginState.typeUser === 1
              ? <Cxc userId={loginState.id} idClient={loginState.idClient} />
              : menuIndex === 1
                ? <Conciliation userId={loginState.idClient} />
                : menuIndex === 2
                  ? <User userId={loginState.id} />
                  // AGREGADO MODULO DE BANCOS
                  : menuIndex === 3
                    ? <Bank userId={loginState.id} />
                    : ''
            }
          </Grid>
        </Grid>
      }
    </React.Fragment>
  );
});

Main.propTypes = {

};

export default Main;