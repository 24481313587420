import { makeStyles } from '@material-ui/core/styles';

export const textfieldStyles = makeStyles({
    input: {
        fontFamily: "Archivo",
        fontSize: "12pt",
        color: 'var(--main-text-color0)',
        // textTransform: 'uppercase',
    },
    underline: {
        '&:hover': {
            borderBottom: '1px solid var(--main-hover-color0)',
        },
        '&:hover:not(.Mui-disabled):before': {
            borderBottom: '2px solid var(--main-hover-color0)',
        },
        '&:before': {
            borderBottom: '2px solid var(--main-bg-color1)',
        },
        '&:after': {
            borderBottom: '1px solid var(--main-hover-color0)',
        }
    },
});

export const textfieldLabelStyles = makeStyles({
    root: {
        fontFamily: "Archivo",
        fontSize: "12pt",
        color: 'var(--main-text-color0)',
        '&$focused': {
            color: 'var(--main-bg-color1)',
        },
    },
    focused: {
        color: 'var(--main-bg-color1)',
    }
});