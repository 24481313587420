import React, { useState } from "react";

const initialState = {
    "id": '',
    "idClient": "",
    "name": "",
    "typeUser": 0,
    "address": "",
    "email": "",
    "phone": "",
    "active": true,
    "client": [],
    isLogin: false,
}

const LoginContext = React.createContext(initialState);
const LoginProvider = ({ children }) => {
    const [loginState, setLoginState] = useState(initialState);
    const setLogin = (data) => setLoginState(data);
    const setLogoff = () => setLoginState(initialState);

    const setLogState = {
        setLogin: setLogin,
        setLogoff: setLogoff,
    }

    return (
        <LoginContext.Provider value={{ loginState, setLogState }}>
            {children}
        </LoginContext.Provider>
    );
};

export { LoginContext, LoginProvider };