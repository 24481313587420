import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import TaTable from '../components/table/TaTable'
import TaTableCell from "../components/table/TaTableCell"
import TaCheckBox from "../components/checkbox/TaCheckBox"
import {
    TableRow,
    Grid,
} from "@material-ui/core/";
import uuid from "react-uuid";
import TaTypography from "../components/typography/TaTypography"

const columnName = [
    {
        id: 0,
        label: 'Fecha',
        width: "75px",
        align: "center",
        hidden: false,
    },
    {
        id: 1,
        label: 'Cliente',
        width: "200px",
        align: "left",
        hidden: false,
    },
    {
        id: 2,
        label: 'Banco',
        width: "120px",
        align: "left",
        hidden: false,
    },
    {
        id: 3,
        label: 'Detalle',
        width: "260px",
        align: "center",
        hidden: false,
    },
    {
        id: 4,
        label: '',
        width: "40px",
        align: "right",
        hidden: false,
    },
];

const ConciliationTable = memo(({ data, onChange, height }) => {
    const tableColumn = useMemo(() => columnName.map(x => <TaTableCell key={uuid()} header={true} align={x.align} width={x.width}>{x.label}</TaTableCell>), [columnName])

    const tableBody = useMemo(() => data.map(x => {
        return (
            <TableRow key={`row-${x.uniqueKey}`}>
                <TaTableCell key={`col0-${x.uniqueKey}`} align={columnName[0].align} width={columnName[0].width}>{x.dateReg}</TaTableCell>
                <TaTableCell key={`col1-${x.uniqueKey}`} align={columnName[1].align} width={columnName[1].width}>{x.name}</TaTableCell>
                <TaTableCell key={`col2-${x.uniqueKey}`} align={columnName[2].align} width={columnName[2].width}>{x.bankName}</TaTableCell>
                <TaTableCell key={`col3-${x.uniqueKey}`} align={columnName[3].align} width={columnName[3].width}>{
                    <Grid container spacing={1} alignContent="center" alignItems="center">
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TaTypography>{`Nro Tran: ${x.numberTrans}`}</TaTypography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TaTypography>{`Fecha: ${x.datePay}`}</TaTypography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TaTypography>{`Monto: ${x.auditAmount.formatNumb(2)}`}</TaTypography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={6} xl={6}>
                            <TaTypography>{x.coin === '$' ? '' : `Factor: ${x.factor.formatNumb(2)}`}</TaTypography>
                        </Grid>
                    </Grid>
                }</TaTableCell>
                {/* <TaTableCell key={`col4-${x.uniqueKey}`} align={columnName[4].align} width={columnName[4].width}>{x.datePay}</TaTableCell>
                <TaTableCell key={`col5-${x.uniqueKey}`} align={columnName[5].align} width={columnName[5].width}>{x.numberTrans}</TaTableCell> */}
                <TaTableCell key={`col6-${x.uniqueKey}`} align={columnName[4].align} width={columnName[4].width}>{
                    <TaCheckBox
                        id={`cbxActive-${x.uniqueKey}`}
                        name="active"
                        checked={x.active}
                        onChange={onChange}
                        uniqueKey={x.uniqueKey}
                        key={`cbxActive-${x.uniqueKey}`} />
                }</TaTableCell>
            </TableRow>
        )
    }), [data, columnName, onChange]);

    return (
        <TaTable
            column={tableColumn}
            body={tableBody}
            usePagination={false}
            height={`${height}px`} />
    );
});

ConciliationTable.propTypes = {
    data: PropTypes.array.isRequired,
};

export default ConciliationTable;