import React, { memo } from 'react'
import propTypes from 'prop-types'

//MUI
import {
    Table,
    TableBody,
    TableHead,
    TableRow,
    TablePagination,
    Paper,
} from '@material-ui/core/'

//Components
const TaTable = memo(({
    column, body, height,
    rowsPerPage, rowsPerPageOptions, currentPage,
    onClickPagination, usePagination, count,
    handleOnChangePage }) => {
    return (
        <Paper
            style={
                {
                    backgroundColor: 'transparent',
                    marginTop: '5px',
                }
            }
            elevation={1}>
            <React.Fragment>
                <Table>
                    <TableHead><TableRow>{column}</TableRow></TableHead>
                </Table>
                <Paper
                    style={
                        {
                            backgroundColor: 'transparent',
                            marginTop: '5px',
                            overflow: 'auto',
                            height: height
                        }
                    }>
                    <Table style={{ tableLayout: 'fixed' }}>
                        <TableBody>{body}</TableBody>
                    </Table>
                </Paper>
                {usePagination
                    && <TablePagination
                        component="div"
                        count={count}
                        rowsPerPage={rowsPerPage}
                        labelRowsPerPage=''
                        rowsPerPageOptions={rowsPerPageOptions}
                        page={currentPage}
                        SelectProps={{
                            native: true,
                        }}
                        onChangePage={handleOnChangePage}
                        onChangeRowsPerPage={onClickPagination} />
                }
            </React.Fragment>
        </Paper>
    )
});

TaTable.propTypes = {
    column: propTypes.array,
    body: propTypes.array,
    height: propTypes.string,
    rowsPerPage: propTypes.number,
    currentPage: propTypes.number,
    rowsPerPageOptions: propTypes.array,
    onClickPagination: propTypes.func,
    usePagination: propTypes.bool,
}

TaTable.defaultProps = {
    column: [],
    body: [],
    height: '300px',
    rowsPerPage: 25,
    rowsPerPageOptions: [25],
    currentPage: 1,
    usePagination: false,
}

export default TaTable