import {fnPostUrl} from "../../../axios/Api"

export const postAuthSaint = (data) => {
    const apiRequest = {
        endpoint: "",
        useBasic: true,
        body: data,
    }

    return fnPostUrl(apiRequest);
}

export const postAuthRest = (data) => {
    const apiRequest = {
        endpoint: "/api/Web/User/Authorize/",
        useBasic: true,
        body: data,
    }

    return fnPostUrl(apiRequest);
}