import React, { memo, useState, useReducer, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';

import ConciliationFilter from "./ConciliationFilter";

import {
  getConciliation,
  postConciliation,
} from "./ApiConciliation";

import ConciliationReducer from "./ConciliationReducer";
import Loader from "../components/loader/Loader";

import {
  CONCILIATION_INITIALSTATE,
} from "./ConciliationConstants"

import {
  setConciliation,
  setOnChangeTable,
  setReset,
} from "./ConciliationActions";

import {
  isOK,
} from "../helper/RestStatus";

import { SnackbarContext } from "../components/snackbar/SnackbarContext";

import {
  Grid,
  Paper,
} from "@material-ui/core";

import useWindowSize from "../helper/UseWindowsSize";
import ConciliationTable from "./ConciliationTable";
import TaButton from '../components/button/TaButton';

import { AuthorizeContext } from "../components/authorize/AuthorizeContext";
import {
  open,
} from "../components/authorize/AuthorizeActions";

const Conciliation = memo(({ userId, userType }) => {
  const [state, dispatch] = useReducer(ConciliationReducer, CONCILIATION_INITIALSTATE);
  const [loader, setLoader] = useState(false);
  const [height, setHeight] = useState("");
  const [disabled, setDisabled] = useState(false);
  const { sbDispatch } = useContext(SnackbarContext);
  const { conciliation } = state;
  const { authDispatch } = useContext(AuthorizeContext);
  const size = useWindowSize();

  const fnOnSearch = async (data) => {
    setLoader(true);
    const response = await getConciliation(data);
    setLoader(false);
    if (isOK(response.status)) {
      if (response.data.errorCode === 0) {
        return dispatch(setConciliation(response.data.data))
      }
    }
    //return sbDispatch.error(response.data.message);
  }

  const fnOnChangeTable = (name, value, uniqueKey) => dispatch(setOnChangeTable(name, value, uniqueKey));

  const fnOnAuthorizeAccept = () => authDispatch(open('Desea aprobar el pago?', 0, { status: 1 }, fnOnPostConciliation))

  const fnOnAuthorizeReject = () => authDispatch(open('Desea rechazar el pago?', 0, { status: 2 }, fnOnPostConciliation))

  const fnOnPostConciliation = async (data) => {
    const selectedPayment = conciliation.find(x => (x.active));
    if (!selectedPayment) {
      return sbDispatch.warning('Seleccione un pago');
    }
    // AGREGADO LOADER
    setLoader(true)
    const response = await postConciliation(selectedPayment, data.status, '');
    setLoader(false)
    sbDispatch.api(response.status, response.data);

    if (isOK(response.status)) {
      if (response.data.errorCode === 0) {
        dispatch(setReset());
        fnOnSearch();
      }
    }
  }
  useEffect(() => {
    fnOnSearch();
    setHeight(size.height - 315);
  }, [])

  useEffect(() => {
    setHeight(size.height - 315);
  }, [size.height])

  useEffect(() => (conciliation.length === 0) ? setDisabled(true) : setDisabled(false), [conciliation])
  return (
    <Paper style={{ padding: "5px" }}>
      <Grid container alignContent="flex-start" alignItems="flex-start" spacing={1}>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <Paper style={{ padding: "5px" }} elevation={0}>
            <ConciliationFilter onSearch={fnOnSearch} disabled={loader} />
          </Paper>
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
          <ConciliationTable data={conciliation} onChange={fnOnChangeTable} height={height} />
        </Grid>
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} align="right">
          <TaButton
            id="btnReject"
            label="Rechazar"
            width="100px"
            backgroundColor="var(--main-bg-color5)"
            onClick={fnOnAuthorizeReject}
            disabled={disabled || loader} />
          <TaButton
            id="btnAccept"
            label="Aprobar"
            width="100px"
            backgroundColor="var(--main-bg-color4)"
            onClick={fnOnAuthorizeAccept}
            disabled={disabled || loader} />
        </Grid>
      </Grid>
      <Loader isLoading={loader} />
    </Paper>
  );
});

Conciliation.propTypes = {
  userId: PropTypes.string,
  userType: PropTypes.number,
};

Conciliation.defaultProps = {
  userType: 1,
}

export default Conciliation;