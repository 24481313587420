import React, { createContext, useReducer } from "react";

import {
    AUTHORIZE_INITIALSTATE,
} from "./AuthorizeConstants";

import AuthorizeReducer from "./AuthorizeReducer";

const AuthorizeContext = createContext(AUTHORIZE_INITIALSTATE);
const AuthorizeProvider = ({ children }) => {
    const [authState, authDispatch] = useReducer(AuthorizeReducer, AUTHORIZE_INITIALSTATE);
    return (
        <AuthorizeContext.Provider value={{ authState, authDispatch }}>
            {children}
        </AuthorizeContext.Provider>
    );
};

export { AuthorizeContext, AuthorizeProvider };