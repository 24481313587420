import { Grid, MenuItem, TextField } from '@material-ui/core'
import React, { memo, useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { RHFInput } from 'react-hook-form-input'
import TaButton from '../components/button/TaButton'
import TaGeneralTitle from '../components/typography/TaGeneralTitle'
import PropTypes from 'prop-types'
import useTextField from '../helper/UseTextField'
import uuid from 'react-uuid'

// AGREGADA LISTA DE MONEDAS COMO CONSTANTE YA QUE SOLO SE USA EN ESTE COMPONENTE
const coinList = [
  {
    id: 1,
    uniqueKey: uuid(),
    name: 'Dolar',
    simbol: '$'
  },
  {
    id: 2,
    uniqueKey: uuid(),
    name: 'Bolivar',
    simbol: 'Bs'
  },
  {
    id: 3,
    uniqueKey: uuid(),
    name: 'Bitcoin',
    simbol: 'BTC'
  },
]

const BankInformation = memo(({ data, disabled, setCurrentBank, accTypeList, fnPostBank }) => {
  const { register, errors, watch, setValue, handleSubmit } = useForm()
  const txtBankName = useTextField('bankName', 'Nombre del banco', errors)
  const txtAccountNumber = useTextField('accountNumber', 'Numero de cuenta', errors)
  const txtAccountType = useTextField('idTypeBankAccount', 'Tipo de cuenta', errors)
  const txtBankRif = useTextField('rif', 'RIF', errors)
  const txtToName = useTextField('accountName', 'Titular de la cuenta', errors)
  const txtBankCoin = useTextField('coin', 'Tipo de moneda', errors)
  const [currentCoin, setCurrentCoin] = useState()
  const [currentAccType, setCurrentAccType] = useState()

  const newAccType = watch('idTypeBankAccount')
  const newCoin = watch('coin')

  useEffect(() => {
    if (!disabled) {
      setValue('bankName', data.bankName)
      setValue('accountNumber', data.accountNumber)
      setValue('rif', data.rif)
      setValue('accountName', data.accountName)
      setValue('coin', data.coin)
      return setValue('idTypeBankAccount', data.idTypeBankAccount)
    }
    setValue('bankName', '')
    setValue('accountNumber', '')
    setValue('rif', '')
    setValue('accountName', '')
    setValue('coin', '')
    return setValue('idTypeBankAccount', '')
  }, [disabled])

  useEffect(() => { if (newCoin !== undefined) setCurrentCoin(coinList.find(x => x.simbol === newCoin)) }, [newCoin])

  useEffect(() => { if (newAccType !== undefined) setCurrentAccType(accTypeList.find(x => x.id === newAccType)) }, [newAccType])

  //AGREGADA LOGICA PARA INACTIVACION DE BANCOS Y PREVENCION DE INACTIVACION SI EL BANCO YA ESTABA ACTIVO Y SE ACTUALIZA
  const fnOnSubmit = async (newData) => {
    setCurrentBank({ ...data, ...newData, active: data.active, coin: currentCoin.simbol, })
    setValue('bankName', '')
    setValue('accountNumber', '')
    setValue('rif', '')
    setValue('accountName', '')
    setValue('coin', '')
    setValue('idTypeBankAccount', '')
    return fnPostBank({ ...data, ...newData, active: (data.active) ? data.active : !data.active, coin: currentCoin.simbol, })
  }

  const fnDisableBank = () => fnPostBank({ ...data, active: false})

  return (
    <Grid container alignContent={'flex-start'} alignItems={'flex-start'} spacing={2}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TaGeneralTitle title={'Información de banco'} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <form onSubmit={handleSubmit(fnOnSubmit)}>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField {...txtBankName} fullWidth disabled={disabled} inputRef={register({ required: 'Requerido' })} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField {...txtAccountNumber} fullWidth disabled={disabled} inputRef={register({ required: 'Requerido' })} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <RHFInput
                as={
                  <TextField {...txtAccountType} select fullWidth disabled={disabled}>
                    {accTypeList && accTypeList.map(acc => <MenuItem key={acc.uniqueKey} value={acc.id}>{`${acc.name}`}</MenuItem>)}
                  </TextField>
                }
                name={'idTypeBankAccount'}
                register={register({ required: "Requerido" })}
                setValue={setValue} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField {...txtBankRif} fullWidth disabled={disabled} inputRef={register({ required: 'Requerido' })} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <TextField {...txtToName} fullWidth disabled={disabled} inputRef={register({ required: 'Requerido' })} />
            </Grid>
            <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
              <RHFInput
                as={
                  <TextField {...txtBankCoin} select fullWidth disabled={disabled}>
                    {coinList && coinList.map(coin => <MenuItem key={coin.uniqueKey} value={coin.simbol}>{`(${coin.simbol}) - ${coin.name}`}</MenuItem>)}
                  </TextField>
                }
                name={'coin'}
                register={register({ required: "Requerido" })}
                setValue={setValue} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'right'} hidden={disabled}>
              <TaButton
                id={'btnDeactivate'}
                backgroundColor={'var(--main-bg-color5)'}
                label={'Desactivar'}
                onClick={fnDisableBank}
                disabled={!(data.active) ? true : disabled} />
            </Grid>
            <Grid item xs={6} sm={6} md={6} lg={6} xl={6} align={'right'} hidden={disabled}>
              <TaButton
                id={'btnUpdate'}
                backgroundColor={'var(--main-bg-color4)'}
                label={'Actualizar'}
                type={'submit'}
                disabled={disabled} />
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
})

BankInformation.propTypes = {
  data: PropTypes.object,
  disabled: PropTypes.bool,
  setCurrentBank: PropTypes.func,
  coinList: PropTypes.array,
  accTypeList: PropTypes.array,
  fnPostBank: PropTypes.func,
};

BankInformation.defaultProps = {
  data: {},
  disabled: true,
  accTypeList: [],
}

export default BankInformation