import React, { memo } from 'react';
import PropTypes from 'prop-types';

import {
    LinearProgress,
    Grid,
} from '@material-ui/core/'

import { makeStyles } from '@material-ui/core/styles';

const LoaderStyles = makeStyles({
    root: {
        color: "var(--main-bg-color0)",
        backgroundColor: "var(--main-bg-color1)",
    },
})

//var(--main-hover-color0)

const Loader = memo(({ isLoading }) => {
    const classes = LoaderStyles();
    return (
        <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ height: "10px" }}>
            {isLoading && <LinearProgress classes={{ root: classes.root }} />}
        </Grid>
    );
});

Loader.propTypes = {
    isLoading: PropTypes.bool,
};

Loader.defaultProps = {
    isLoading: false
}

export default Loader;