import { Grid, List, ListItem, Paper } from '@material-ui/core'
import React, { memo, useState } from 'react'
import PropTypes from 'prop-types';
import TaGeneralTitle from '../components/typography/TaGeneralTitle'
import { fnSortData } from '../helper/DataModify'
import ListStyle from '../styles/List'

const BankList = memo(({ heightB, data, isLoading, onClick }) => {
  const classes = ListStyle({ hover: true })
  const fnOnClick = (item) => {
    setCurrentBank(item.uniqueKey)
    onClick(item.bankCode)
  }
  const [currentBank, setCurrentBank] = useState('')
  return (
    <Grid container spacing={1}>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <TaGeneralTitle title={'Bancos'} />
      </Grid>
      <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
        <Paper id={'listContainer'} elevation={0} style={{ padding: '5px', height: heightB, overflowX: 'hidden', overflowY: 'auto' }}>
          <List component={'nav'}>
            {data && fnSortData(data).map(item => {
              return (
                <ListItem
                  key={item.uniqueKey}
                  classes={{ root: classes.root, selected: classes.selected }}
                  selected={item.uniqueKey === currentBank}
                  onClick={isLoading ? undefined : () => fnOnClick(item)}>
                  <Grid container style={{ margin: "5px" }} alignItems="center" alignContent={"flex-start"} spacing={1}>
                    <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>{item.bankName}</Grid>
                  </Grid>
                </ListItem>
              )
            })}
          </List>
        </Paper>
      </Grid>
    </Grid>
  )
})
BankList.propTypes = {
  data: PropTypes.array,
  isLoading: PropTypes.bool,
  onClick: PropTypes.func,
};
BankList.defaultProps = {
  data: [],
  isLoading: false,
  onClick: {},
}

export default BankList
